import { Box, Button, FormControl, FormLabel, Input, Select, Textarea, Image, Stack, Center, useColorModeValue } from "@chakra-ui/react";
import { useState } from "react";
import { useFirestoreContext } from '../../FirestoreContext'
import { useAuthContext } from "../../AuthContext";
import { Timestamp } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

export function Formulario({ question_id, updateResponses }) {
  const navigate = useNavigate();
  const { createResponse } = useFirestoreContext();
  const { userData } = useAuthContext();
  const [titulo, setTitulo] = useState("");
  const [contenido, setContenido] = useState("");
  const [categoria, setCategoria] = useState("");
  const [imagenes, setImagenes] = useState([]);
  const textColor = useColorModeValue('blue.500', 'blue.500');
  const bgColor = useColorModeValue('gray.200', 'gray.700');

  const handleSubmit = (event) => {
    event.preventDefault();
    createResponse({
                    question_id: question_id,
                    content: contenido,
                    author: {
                      name: userData.displayName,
                      image: userData.photoURL,
                      userType: userData.userType,
                      // TODO: Es mejor que al leer la pregunta de la db, se lean los datos actualizados del usuario, en lugar de escribirlos aca
                      isVerified: userData.isVerified,
                      isPremium: userData.isPremium
                    },
                    date: Timestamp.now(),
                  })
    .then((res) => {
                    console.log(res.id);
                    updateResponses();  
                  });
  };

  return (
    <Box 
      as="form" 
      onSubmit={handleSubmit} 
      p={5} 
      border="1px" 
      borderColor="gray.200" 
      borderRadius="md" 
      shadow="sm"
      mx="auto"
    >

      <FormControl id="contenido">
        <FormLabel>Escribe tu respuesta</FormLabel>
        <Textarea value={contenido} onChange={(e) => setContenido(e.target.value)} />
      </FormControl>


      <Center mt={3}>
        <Button color={'white'}
              bg="blue.500"
              _hover={{
                textDecoration: 'none',
                color: textColor,
                bg: bgColor
              }} type="submit" colorScheme="blue">
          Enviar
        </Button>
      </Center>
    </Box>
  );
}
