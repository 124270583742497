import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Stack,
  Textarea,
  Tooltip,
  useClipboard,
  useColorModeValue,
  VStack,
  Select,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../AuthContext"
import { useFirestoreContext } from '../../FirestoreContext';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';


export function Appointments(props){


  const { t,  i18n } = useTranslation();
  const [appointment, setAppointment] = useState (true);
  const { createAppointment, getServices } = useFirestoreContext();
  //const [appointmentData, setAppointmentData] = useState(true);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { isLoggedIn } = useAuthContext();
  const { userData } = useAuthContext();
  const [loaded, setLoaded] = useState(false);
  const [services, setServices] = useState([]);
  const [dateRef, setDateRef] = useState(null);
  const [timeRef, setTimeRef] = useState(null);
  const [typeRef, setTypeRef] = useState(null);
  const [messageRef, setMessageRef] = useState(null);
  const [phoneRef, setPhoneRef] = useState(null);
  const bgColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('blue.500', 'blue.500');


  
  useEffect(() => {
    if (isOpen) {
      clearForm();
    }
  }, [isOpen]);

  const clearForm = () => {
    setDate("");
    setTime("");
    setType("");
    setMessage("");
    setPhone("");
  };

  useEffect(() => {
    if (!isOpen) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isOpen]);

  useEffect(() => {
    fetchServices();
  }, [i18n.language]); // Re-run effect when language changes

  const fetchServices = () => {
    getServices()
      .then((res) => {
        setServices(res);
      })
      .catch((error) => {
        console.error('Error fetching services:', error);
        // Handle error if needed
      });
  };

    useEffect(() => {
      getServices()
        .then((res) => {
          setServices(res); // Set services directly, assuming res is an array
        })
        .catch((error) => {
          console.error('Error fetching services:', error);
          // Handle error if needed
        });
    }, []);


    const handleSubmit = async (e) => {
      e.preventDefault();
      const field = Math.floor(Math.random() * (1000000 - 1 + 1)) + 1;
      const data = {
        [field]: {
          date: date,
          time: time,
          type: type,
          message: message,
          phone: phone,
          email: userData.email
        }
      };
   
      createAppointment(props.url, data).then(() => setAppointment(false));
      setIsOpen(true);
    };

    const onClose = () => {
      setIsOpen(false); // Asegúrate de cerrar la ventana modal antes de hacer el scrollTo
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
    
    const handleClose = () => {
      toast('Cita solicitada exitosamente', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        onClose: () => {
          // Wait for the toast to be displayed before closing the modal
          onClose();
          props.onTabChange(0); // Cambia a la pestaña 'bio'
        },
      });
    };
    
    if (!isLoggedIn) {
      return (
          <Box textAlign="center" p={8}>
            <Text fontSize="xl" fontWeight="bold">
              {t("profileContent.loginRequiredAppointment")}
            </Text>
          </Box>
      )
    }

  return(
      <Flex
      bg={bgColor}
      align="center"
      justify="center"
      id="contact">
        <ToastContainer />
    <Box borderRadius="lg" m={{ base: 5, md: 16, lg: 10 }} p={{ base: 5, lg: 16 }}>
      <Box w='700px'>
        <VStack spacing={{ base: 4, md: 8, lg: 20 }}>
          <Heading
            fontSize={{
              base: '4xl',
              md: '5xl',
            }}>
            {t("appointments.makeAppointment")}
          </Heading>
            <Box
              bg={bgColor}
              borderRadius="lg"
              p={8}
              shadow="base"
              w='95%'>
              <form onSubmit={handleSubmit}>
                <FormControl isRequired>
                  <FormLabel m={2}>{t("appointments.selectDate")}</FormLabel>
                  <InputGroup>
                    <Input type="date"
                           name="date"
                           value={date}
                           m={2}
                           borderColor="#00aad0"
                           onChange={(e) => setDate(e.target.value)}
                           ref={(input) => setDateRef(input)}
                   />
                  </InputGroup>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel m={2}>{t("appointments.selectTime")}</FormLabel>
                  <InputGroup>
                    <Select
                      m={2}
                      borderColor="#00aad0"
                      name="hour"
                      value={time.split(':')[0]}
                      onChange={(e) => {
                        const selectedHour = e.target.value;
                        const newTime = `${selectedHour}:${time.split(':')[1]}`;
                        setTime(newTime);
                      }}
                      ref={(select) => setTimeRef(select)}
                    >
                      {[...Array(13).keys()].map((hour) => (
                        <option key={hour} value={hour + 7}>{hour + 7}:00</option>
                      ))}
                    </Select>
                    <Select
                      m={2}
                      name="minute"
                      borderColor="#00aad0"
                      value={time.split(':')[1]}
                      onChange={(e) => {
                        const selectedMinute = e.target.value;
                        const newTime = `${time.split(':')[0]}:${selectedMinute}`;
                        setTime(newTime);
                      }}
                    >
                      {[0, 30].map((minute) => (
                        <option key={minute} value={minute}>{minute === 0 ? '00' : '30'}</option>
                      ))}
                    </Select>
                  </InputGroup>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel m={2}>{t("appointments.type")}</FormLabel>
                  <Select onChange={(e) => setType(e.target.value)} ref={(select) => setTypeRef(select)}>
                  {services.map((item, key) => (
                    <option key={key} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel m={2}>{t("appointments.message")}</FormLabel>
                  <InputGroup>
                    <Textarea m={2}
                              borderColor="#00aad0"
                              type="text"
                              name="message"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              ref={(textarea) => setMessageRef(textarea)}  
                    />
                  </InputGroup>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel m={2}>{t("appointments.phone")}</FormLabel>
                  <InputGroup>
                    <Input    type="text"
                              name="phone"
                              value={phone}
                              m={2}
                              borderColor="#00aad0"
                              onChange={(e) => setPhone(e.target.value)}
                              ref={(input) => setPhoneRef(input)}
                     />
                  </InputGroup>
                </FormControl>


                <Button
                  type='submit'
                  color={'white'}
                  bg={'blue.500'}
                  rounded={'md'}
                  _hover={{
                    transform: 'translateY(-2px)',
                    boxShadow: 'lg',
                    color: textColor,
                    bg: bgColor
                  }}
                  width="100%">
                  {t("appointments.sendAppointment")}
                </Button>
                </form>
            </Box>
        </VStack>
      </Box>
    </Box>
    <Modal isOpen={isOpen} onClose={onClose} onCloseComplete={handleClose}>
      <ModalOverlay />
      <ModalContent bg={bgColor} borderRadius="lg">
        <ModalHeader>{t("appointments.modalTittle")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{t("appointments.modalText")}</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>
            Cerrar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </Flex>
  )
}
