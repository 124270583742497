import React, { useState } from 'react';
import { Stack, Text, Button, useColorModeValue, Icon} from '@chakra-ui/react';
import { MdQuestionAnswer } from 'react-icons/md';
import { Formulario } from './NewQuestionForm.js';
import { useTranslation } from 'react-i18next';


export default function NewQuestion({isLoggedIn}) {
  const [openForm, setOpenForm] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <>
    <Stack p="4" boxShadow="lg" m="4" borderRadius="sm">
      <Stack direction="row" alignItems="center">
        <Text fontWeight="semibold" color={"blue.500"}>{t("forumInstructions.newQuestion")}</Text>
        <Icon as={MdQuestionAnswer} color={"gray.500"} />
      </Stack>

      <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
        <Text fontSize="md" mb={4}>
        {t("forumInstructions.questionDescription")}
        </Text>
        {isLoggedIn ?

          <Stack direction={{ base: 'column', md: 'row' }}>
            <Button
              colorScheme="blue"
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
                bg: "gray.200",
                textDecoration: 'blue.500'
              }}
              onClick={() => setOpenForm(!openForm)}
            >
              {t("forumInstructions.askButton")}
            </Button>
          </Stack>
        :
        <Stack direction={{ base: 'column', md: 'row' }}>
            <Button
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
              bg: "gray.200",
              textDecoration: 'blue.500'
            }}
              colorScheme="blue"
            >
              Iniciar Sesion
            </Button>
          </Stack>
        }

      </Stack>

      
    </Stack>
    {openForm ? <Formulario></Formulario> : null}
    </>
  );
}
