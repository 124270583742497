import {React, useState, useEffect} from 'react'
import  Header from "../components/Header.js"
import Footer  from "../components/Footer.js"
import  {ListDoctors} from "../components/directory/ListDoctors"
import { useTranslation } from 'react-i18next'
import Sidebar from "../components/sidebar/Sidebar.js"
import { Stack, Box, HStack, VStack, Button, chakra, Select, SimpleGrid, Spinner, useColorModeValue, Divider, Heading } from "@chakra-ui/react"
import { useDirectoryContext } from '../DirectoryContext';
import { useFirestoreContext } from '../FirestoreContext';
import { MultiSelect } from 'chakra-multiselect';

export default function Directory () {
  const { t,  i18n } = useTranslation();
  const { getDoctors } = useDirectoryContext();
  const [doctors, setDoctors] = useState([]);     // son los medicos que se muestran al filtrar
  const [doctorsList, setDoctorsList] = useState([]);    // Es la lista completa de medicos al ejecutar la busqueda
  const [ loaded, setLoaded ] = useState(0);
  const textColor = useColorModeValue('blue.500', 'blue.500');
  const bgColor = useColorModeValue('gray.200', 'gray.700');
  
  const { getLanguages, getServices, getPaymentMethods, getCities, getDisease } = useFirestoreContext();


  const [diseases, setDiseases] = useState ();
  const [tempDiseases, setTempDiseases] = useState();
  const [diseasesOptions, setDiseasesOptions] = useState([]);   // hay que modificar el formato de las enfermedades para poder hacer la busqueda, esta variable contiene esas opciones

  const [languages, setLanguages] = useState([]);
  const [tempLang, setTempLang] = useState([{value: "Español"}]); // Se pone idioma por defecto español

  const [services, setServices] = useState ([]);
  const [tempServices, setTempServices] = useState([]);

  const [payment, setPayment] = useState ([]);
  const [tempPayment, setTempPayment] = useState([]);

  const [cities, setCities] = useState ([]);
  const [tempCities, setTempCities] = useState([]);

  useEffect( () => {

        if (diseases) {
              
              const resultList = [];
                // Iterate over each field (enfermedades)
              for (const category in diseases ) {
                  // Iterate over each value in the field\
                  diseases[category].forEach((value) => {
                    // Create a string and push it to the result array
                        resultList.push({category: category, disease: value})
                        });
                  };
              setDiseasesOptions(resultList);
              }
        }

   , [diseases])


  function handleApply(){
    
    var tempDoctors = []
    var tempResult = []
    var langs =  tempLang.map((val) => val.value)
    var serv =  tempServices.map((val) => val.value)
    var pay = tempPayment.map((val) => val.value)
    var city = tempCities.map((val) => val.value)

    console.log(langs)

    // Filtro por idioma
    //--------------------
    const initList = doctorsList

    const filters = [["languages", langs],
                      ["services", serv],
                      ["paymentMethods", pay], 
                      ["city", city]
                    
                    ]
    /*
    filters.forEach(([filter, variable]) => {
      doctorsList.forEach((doctor) => {

       if (doctor[filter] && doctor[filter].some((elemento) => variable.includes(elemento))) {
            console.log(1);
       }

      })

    });
    */

    if (langs.length > 0){
        doctorsList.forEach((doctor) => {
          if (doctor.languages && doctor.languages.some((elemento) => langs.includes(elemento))) {
              tempResult.push(doctor);
             }
        })};


    tempDoctors = tempResult;
    tempResult = []


    // Filtro por Servicios
    //---------------------
    if (serv.length > 0) {
        tempDoctors.forEach((doctor) => {
          if (doctor.services && doctor.services.some((elemento) => serv.includes(elemento))) {
              tempResult.push(doctor);
          }
        });
    } else {
      tempResult = tempDoctors; // Si no hay ninguna opcion, se continua con los medicos que ya se tenian
    }

    tempDoctors = tempResult;
    tempResult = []

    // Filtro por forma de pago
    //-------------------------
    if (pay.length > 0) {
      tempDoctors.forEach((doctor) => {
        if (doctor.paymentMethods && doctor.paymentMethods.some((elemento) => pay.includes(elemento))) {
            tempResult.push(doctor);
        }
      });
      } else {
        tempResult = tempDoctors; // Si no hay ninguna opcion, se continua con los medicos que ya se tenian
      }
      tempDoctors = tempResult;
      tempResult = []

    // Filtro por ciudad
    //-------------------------
    if (city.length > 0) {
      tempDoctors.forEach((doctor) => {
        if (city.includes(doctor.city)) {
            tempResult.push(doctor);
        }
      });
      } else {
        tempResult = tempDoctors; // Si no hay ninguna opcion, se continua con los medicos que ya se tenian
      }
      tempDoctors = tempResult;
      tempResult = []




    setDoctors(tempDoctors);
  }

  function handleSearch(){
    
    getDoctors(tempDiseases).then((data) => {console.log(data); setDoctors(data); setDoctorsList(data)});
  }

  function LeftBar(){
    return (
        <VStack >
            <Heading mt={2} color={"blue.500"} fontSize={"18"}> Buscar </Heading>


            <Select
                placeholder="Selecciona una opción"
                value={tempDiseases}
                onChange={(event) => {console.log(event.target.value); setTempDiseases(event.target.value)}}>

                      {diseasesOptions.map((val) => <option key={val.disease} style={{ fontSize: "12px" }} value={`${val.category}-${val.disease}`}> 
                                                                                                          {`${val.category.replace(/_/g, ' ')}-${val.disease}`}
                       </option>) }
              </Select>

            <Button 
              color={'white'}
              bg="blue.500"
              _hover={{
                textDecoration: 'none',
                color: textColor,
                bg: bgColor
              }}
               width="100%" onClick={handleSearch}>{t("directory.search")}</Button>

            <Divider mb={10}></Divider>

            <Heading mt={"10"} color={"blue.500"} fontSize={"18"}> Filtrar </Heading>

            <MultiSelect
                
                options= { Object.values(languages).map((val) => ({value:val})) } 
                value={tempLang}
                label={t("profileContent.languages")}
                onChange={setTempLang}/>

            <MultiSelect
                options= { Object.values(services).map((val) => ({value:val})) } 
                value={tempServices}
                label={t("profileContent.services")}
                onChange={setTempServices}/>

            <MultiSelect
                options= { Object.values(payment).map((val) => ({value:val})) } 
                value={tempPayment}
                label={t("profile.paymentMethod")}
                onChange={setTempPayment}/>

            <MultiSelect
                options= { Object.values(cities).map((val) => ({value:val})) } 
                value={tempCities}
                label={t("registerDr.city")}
                onChange={setTempCities}/>

              <Button 
              color={'white'}
              bg="blue.500"
              _hover={{
                textDecoration: 'none',
                color: textColor,
                bg: bgColor
              }}
               width="100%" onClick={handleApply}>{t("directory.applyFilters")}</Button>
        </VStack>
    )
  };

  if (!loaded) {
    Promise.all([getDoctors(), getServices(), getLanguages(), getPaymentMethods(), getCities(), getDisease()])
      .then(([doctors, services, languages, payment, cities, diseases]) => {
        setDoctors(doctors);
        setDoctorsList(doctors);
        setServices(services);
        setLanguages(languages.data());
        setPayment(payment.data());
        setCities(cities.data());
        
        // Establecer el estado "loaded" una vez que todas las promesas se completen
        

        const resultList = [];
  
        // Iterate over each field (enfermedades

        
        setDiseases(diseases.data());
        setLoaded(true);
      })
      .catch((error) => {
        // Manejar cualquier error que pueda ocurrir con alguna de las promesas
        console.error('Error:', error);
      });
  }
  

  return (
    
    <Stack w={['100%', "90%", "90%", "90%", "80%", "80%"]} direction={"column"}
    px={2}>
    <Header></Header>
    <chakra.h2 fontSize="2xl" fontWeight="700" my={10} color={'blue.500'}>
        {t("directory.specialist")}
      </chakra.h2>

     
      <Stack
        width={"100%"}
        direction={['column', "column", "column", "column", "column", "row"]} // Vertical en móvil, horizontal en desktop

        spacing={4}
        
         >
          <Stack
              direction={['column', "column", "column", 'row']}
              width={"100%"}
              spacing={10}>

              <Box width={"100%"}>
                {loaded ? <LeftBar> </LeftBar> : <Spinner></Spinner>}
              </Box>

              <Box width={'100%'}>
                <Heading fontSize={22} color={"blue.500"}>Resultados</Heading>
                {loaded ? <ListDoctors posts={doctors}></ListDoctors> : <Spinner></Spinner>}
              </Box>
          </Stack>  

          <VStack  width={['100%', "100%", "100%", "100%", "100%", "30%"]} overflow={'hidden'}>
                 <Sidebar as={Stack} width={'100%'} margin="auto" ></Sidebar>
          </VStack>
        
    </Stack>

    <Footer></Footer>
    </Stack>
    
  )
}


