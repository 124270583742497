import { Button, Divider, Flex, Menu, MenuItem, MenuButton, MenuList, Link } from "@chakra-ui/react"
import { LockIcon, ChevronDownIcon } from "@chakra-ui/icons"
import { MdMedicalServices } from "react-icons/md"
import { useState } from "react";
import ModalLogin from "./Modal";
import { useTranslation } from 'react-i18next';
//import { Link } from "react-router-dom";




export function LoginBar () {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const onClose = () => setIsOpen(false);
    const onOpen = () => setIsOpen(true);


    const toggleModal = () => {
        setIsOpen(!isOpen);
       };
return (
    <Flex alignItems="center" justifyContent="space-around" flexDirection={{base: "column", sm: "row"}} zIndex={1000}>
   
      <Menu m={2}>
        <MenuButton color={'blue.500'} as={Button} rightIcon={<ChevronDownIcon />} leftIcon={<LockIcon />} variant='ghost' >
        {t("loginbar.login")}
        </MenuButton>
        <MenuList>
          <MenuItem onClick={toggleModal} > {t("loginbar.login")}  </MenuItem>
          <Link href="/register">
          <MenuItem > {t("loginbar.register")}  </MenuItem>
          </Link>
        </MenuList>
      </Menu>

      <Menu>
        <MenuButton color={'blue.500'} as={Button} rightIcon={<MdMedicalServices/>} variant='ghost'>
        {t("loginbar.doctorslogin")}
        </MenuButton>
        <MenuList>
          <MenuItem onClick={toggleModal} > {t("loginbar.doctorslogin")} </MenuItem>
          <Link href="/signup-doctor">
          <MenuItem > {t("loginbar.doctorsregister")}  </MenuItem>
          </Link>
        </MenuList>
      </Menu>
          <ModalLogin isOpen={isOpen} onClose={toggleModal} />
    </Flex>
  )
}
