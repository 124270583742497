import { VStack, Button, HStack, Tooltip, Box, Flex, useColorModeValue} from '@chakra-ui/react';
import { MdOutlineContactPage, MdOutlineEditNote, MdCompost } from "react-icons/md";
import { Pricing } from "./Pricing";
import { useState, useEffect } from "react";
import { EditProfile } from "./EditProfile";
import {EditPublicInfo} from "./EditPublicInfo";
import { useAuthContext } from "../../AuthContext";
import { CreateArticle } from "./CreateArticle";
import { useNavigate } from 'react-router-dom';
import { Appointments } from './Appointments';
import { ChangePassword } from './ChangePassword';
import { ViewIcon, UnlockIcon, EditIcon, InfoIcon, QuestionOutlineIcon, AttachmentIcon } from '@chakra-ui/icons'
import ExperiencesGallery from './ExperiencesGallery';
import { useTranslation } from 'react-i18next';




export function Dashboard() {
    const { t, i18n } = useTranslation();
    const [displayElement, setDisplayElement] = useState(<EditProfile/> );
    const { userData } = useAuthContext();
    const handleClick = (value) => setDisplayElement(value);
    const navigate = useNavigate();

    const MenuItems = [
        {text: t("dashboardContent.editYourInfo"), content: <EditProfile/> , premium:false, icon: <MdOutlineContactPage color="teal"/>},
        {text: t("dashboardContent.changePassword"), content: <ChangePassword/> , premium:false, icon: <UnlockIcon color="purple"/>},
        {text: t("dashboardContent.editYourPage"), content: <EditPublicInfo/> , premium:false, icon: <EditIcon color="red"/>},
        {text: t("dashboardContent.articles"), content: <CreateArticle/> , premium:false, icon: <InfoIcon color="green"/>},
        {text: t("dashboardContent.appointments"), content: <Appointments/> , premium:false, icon: <QuestionOutlineIcon color="cyan"/>},
        {text: t("dashboardContent.experiencesGalery"), content: <ExperiencesGallery/> , premium:false, icon: <AttachmentIcon color="blue"/>},
    ]

    const bg= useColorModeValue("gray.100","gray.700");
    if (!(userData.userType==="doctor")){    /// Si el usuario no es un medico, devuelve componente vacio
        return (<></>)
    }
    else{

        for (let i = 0; i < MenuItems.length; i++) {
            if (MenuItems[i].premium && userData.isPremium) { // Si el usuurio es premium desbloquea la opcion
                MenuItems[i].premium = false ; 
            }
          }

    return (
    <Flex mt={6} 
    width={"100%"}
     align="start" 
     borderRadius="md"
      bg={bg} p={7}
      direction= {['column', null, null, 'row']}>
        <VStack borderWidth={"2px"} p={1} align="flex-start" borderRadius="md" mx={["auto", 5, 5, 10]} >

                <Tooltip label="View Profile" aria-label='A tooltip'>
                        <Button w={'full'} variant='outline'  leftIcon={ <ViewIcon/>} onClick={() => navigate('/doctor/' + userData.profileURL)}> {t("dashboardContent.viewProfile")} </Button>
                </Tooltip>
            {MenuItems.map((value, key) => 
                <Tooltip key={key} label={value.premium ? "Premium Feature": value.text} aria-label='A tooltip'>
                        <Button w={'full'} variant='outline'  leftIcon={value.icon} isDisabled={value.premium} onClick={() => handleClick(value.content)}> {value.text} </Button>
                </Tooltip>
            )}
        </VStack>
        <Box w={"100%"} mx={[0, 0, "auto", "auto"]}>
            {displayElement}
        </Box>
    </Flex>

        )
    }
}