import {
  Flex,
  Icon,
  Box,
  Avatar,
  Stack,
  Text,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  HStack,
  Tag,
  TagLeftIcon,
  TagLabel
} from '@chakra-ui/react'
import { AddIcon, CheckIcon, StarIcon } from '@chakra-ui/icons'
import { RiServiceFill} from 'react-icons/ri';
import { MdPayment, MdLocationCity} from "react-icons/md";
import { IoLanguageOutline } from "react-icons/io5";
import {Bio} from "./Bio"
import {Opinions} from "./Opinions"
import {Appointments} from "./Appointments"
import Gallery from "./Gallery"
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

export function Content({pageData}){
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const { t, i18n } = useTranslation();
    console.log(pageData);

    return(
    <Flex
      width="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="space-between"
    >
      <Box
        width="100%"
        maxHeight="300px"
        overflow="visible"
        height="300px"
        backgroundImage={`url('${pageData.coverImage}')`}
        backgroundSize="cover"
        position="relative"
      >
         <Box
          position="absolute"
          bottom="-10"
          left="0"
          ml={4} // Ajusta el margen izquierdo según sea necesario
          mb={4} // Ajusta el margen inferior según sea necesario
        >
          <Avatar
            src={pageData.photoURL}
            boxSize="180px"
            borderRadius="md"
          />
        </Box>
      </Box>


      <Stack spacing={2} flexDirection="column" p={2} mt={6}>
        <Heading color={'blue.500'}>{pageData.displayName}</Heading>
        <Text color="gray.500">{pageData.speciality}</Text>
        <Text color="gray.500"><Icon as={MdLocationCity} w={"30px"} h={"30px"} mx={1} color='blue.300' />{pageData.city}</Text>


        <HStack w="100%">
        <Box w="50%">
        <Text
                fontSize={{ base: '10px', lg: '14px' }}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={4}
                mt={4}>
                  <Icon as={RiServiceFill} w={"18px"} h={"18px"} mx={1} color='blue.300' />
                  {t("profileContent.services")}
              </Text>
              <Stack direction='row'>
                { pageData.services && pageData.services.map((service,key) =>
                  <Tag size="md" variant='subtle' colorScheme='blue' key={key}>
                  <TagLeftIcon boxSize='10px' as={CheckIcon} />
                  <TagLabel>{service.label || service}</TagLabel>
                </Tag>
                )
                }
               
            </Stack>
           
            <Text
                fontSize={{  base: '10px', lg: '14px'  }}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={4}
                mt={4}>
                   
                    <Icon as={MdPayment} w={"18px"} h={"18px"} mx={1} color='blue.300' />
                    {t("profileContent.paymentMethod")}
              </Text>


            <Stack direction='row'>
                { pageData.paymentMethods && pageData.paymentMethods.map((service,key) =>
                  <Tag size="md" variant='subtle' colorScheme='blue' key={key}>
                  <TagLeftIcon boxSize='10px' as={CheckIcon} />
                  <TagLabel>{service.label || service}</TagLabel>
                </Tag>
                )
                }
            </Stack>




            <Text
                fontSize={{ base: '10px', lg: '14px'   }}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={4}
                mt={4}>
                   
                    <Icon as={IoLanguageOutline} w={"18px"} h={"18px"} mx={1} color='blue.300' />
                    {t("profileContent.languages")}
              </Text>


            <Stack direction='row'>
                { pageData.languages && pageData.languages.map((service,key) =>
                  <Tag size="md" variant='subtle' colorScheme='blue' key={key}>
                  <TagLeftIcon boxSize='10px' as={CheckIcon} />
                  <TagLabel>{service.label || service}</TagLabel>
                </Tag>
                )
                }
            </Stack>


        </Box>


        <Box w="50%">
        <Text
                fontSize={{ base: '18px', lg: '22px' }}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={10}
                mt={10}>
                    {t("profileContent.info")}
              </Text>
          <Text fontSize={{ base: '14px', lg: '18px' }}>
            {pageData.header}
          </Text>
             
        </Box>
        </HStack>
        <Tabs size="md" index={activeTab} onChange={handleTabChange}>
          <TabList>
            <Tab>{t("profileContent.bio")}</Tab>
            <Tab>{t("profileContent.opinions")}</Tab>
            <Tab>{t("profileContent.appointments")}</Tab>
            <Tab>{t("profileContent.galery")}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
                <Bio text={pageData.bio}/>
            </TabPanel>
            <TabPanel>
                <Opinions/>
            </TabPanel>
            <TabPanel>
            <Appointments
              url={pageData.profileURL}
              activeTab={activeTab} // Pasa el estado activeTab como prop
              onTabChange={handleTabChange} // Pasa la función handleTabChange como prop
            />
            </TabPanel>
            <TabPanel>
                <Gallery/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Flex>


)
}
