import {React, useState} from 'react'
import  Header from "../components/Header.js"
import { FirestoreProvider, useFirestoreContext } from '../FirestoreContext'
import Footer  from "../components/Footer.js"
import RecentQuestions from "../components/forum/RecentQuestions.js"
import  NewQuestion from "../components/forum/NewQuestion.js"
import  { ListQuestions } from "../components/forum/ListQuestions.js"
import { useTranslation } from 'react-i18next'
import { useAuthContext } from "../AuthContext";
import ForumInstructions from "../components/ForumInstructions.js"
import Sidebar from "../components/sidebar/Sidebar.js"
import { Flex, Box, HStack, Button, useColorModeValue, Stack } from "@chakra-ui/react"

export default function Forum () {
  const { t,  i18n } = useTranslation();
  const { lastQuestions } = useFirestoreContext();
  const [posts, setPosts] = useState([]);
  const [ loaded, setLoaded ] = useState(0);
  const [ option, setOption ] = useState("forum");
  const { userData, isLoggedIn } = useAuthContext(); 
  const [isOpen, setIsOpen] = useState(false);
  const { isSignedUp } = false;
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  const toggleModalT = () => {
      console.log(isOpen);
      setIsOpen(!isOpen);
     };

  if (!loaded) {
    const sisas = lastQuestions().then(querySnapshot => {
        const preguntas = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setPosts(preguntas);
        setLoaded(1);
      }
    );

  }

  return (
    <Stack w={['100%', null, null, '80%']} direction={['column', null, null, 'column']}
    px={2}>
    <Header></Header>
    <HStack align="flex-start">
        <Box w={"auto"}>
        <Button ml={4} mt={3}  onClick={toggleModalT} colorScheme="blue"
        _hover={{
          transform: 'translateY(-2px)',
          boxShadow: 'lg',
          bg: useColorModeValue('gray.200', 'gray.700'),
          textDecoration: 'none', color: useColorModeValue('blue.500', 'blue.500')
        }}> 
                  {t("forumInstructions.title")}                  
                  </Button>
                  { isSignedUp ? toggleModalT() :
                    <ForumInstructions isOpen={isOpen} onClose={toggleModalT} />
                  }
          <NewQuestion isLoggedIn={isLoggedIn} ></NewQuestion>
          { loaded ? <ListQuestions posts={posts} ></ListQuestions>: <></> }
          { loaded ? <RecentQuestions posts={posts} ></RecentQuestions>: <></> }
        </Box>
        

    </HStack>
    <Footer></Footer>
    </Stack>
  )
}
