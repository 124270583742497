import { Box, Button, FormControl, FormLabel, Input, Select, Textarea, Image, Stack, Center, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFirestoreContext } from '../../FirestoreContext'
import { useAuthContext } from "../../AuthContext";
import { Timestamp } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { RichTextEditor } from "../TextEditor";
import { DropzoneArea } from "material-ui-dropzone";
import { getDownloadURL } from "firebase/storage"

export function Formulario() {
  const navigate = useNavigate();
  const { createQuestion, fileUpload } = useFirestoreContext();
  const { userData } = useAuthContext();
  const [titulo, setTitulo] = useState("");
  const [contenido, setContenido] = useState("");
  const [categoria, setCategoria] = useState("");
  const [imagenes, setImagenes] = useState([]);
  const [files, setFiles] = useState([]);
  const textColor = useColorModeValue('blue.500', 'blue.500');
  const bgColor = useColorModeValue('gray.200', 'gray.700');

  useEffect(() => console.log(contenido), []);

  const handleSubmit = (event) => {
    event.preventDefault();

    let urls = [];
const uploadPromises = files.map((file) => {
  return fileUpload(file, "questions").then((snapshot) => {
                                                            console.log(snapshot);
                                                            urls.push(snapshot);
                                                          })
});

Promise.all(uploadPromises)
  .then(() => {
    var data = {
      title: titulo,
      content: contenido,
      author: {
        name: userData.displayName,
        image: userData.photoURL,
        userType: userData.userType,
        // TODO: Es mejor que al leer la pregunta de la db, se lean los datos actualizados del usuario, en lugar de escribirlos aca 
      },
      date: Timestamp.now(),
      images: urls,
    };

    if (userData.userType==="doctor"){
      data.isVerified= userData.isVerified;
      data.isPremium= userData.isPremium
    }
    createQuestion(data)
      .then((res) => {
        console.log(res.id);
        navigate(`/question/${res.id}`);
      })
      .catch((error) => {
        console.error('Error al crear la pregunta:', error);
      });
  })
  .catch((error) => {
    console.error('Error al subir archivos:', error);
  });

  };

  return (
    <Box 
      as="form" 
      onSubmit={handleSubmit} 
      p={5} 
      border="1px" 
      borderColor="gray.200" 
      borderRadius="md" 
      shadow="sm"
      mx="auto"
      my={5}
    >
      <FormControl id="titulo">
        <FormLabel>Título</FormLabel>
        <Input type="text" value={titulo} onChange={(e) => setTitulo(e.target.value)} />
      </FormControl>

      <FormControl id="contenido">
        <FormLabel>Contenido</FormLabel>
        <RichTextEditor value={contenido} onChange={setContenido} />
      </FormControl>

      <FormControl id="categoria">
        <FormLabel>Categoría</FormLabel>
        <Select placeholder="Selecciona categoría" value={categoria} onChange={(e) => setCategoria(e.target.value)}>
          <option value="categoria1">Categoría 1</option>
          <option value="categoria2">Categoría 2</option>
          <option value="categoria3">Categoría 3</option>
        </Select>
      </FormControl>

      <FormControl id="fotos">
        <FormLabel>Cargar fotos</FormLabel>
        <Stack direction="row" overflowX="auto" mt={3}>
          <DropzoneArea
              maxFileSize={3000000}
              filesLimit={3}
              acceptedFiles={['image/*']}
              dropzoneText={"Drag and drop an image here or click"}
              onChange={(filesvar) => {setFiles(filesvar)}}
              />
        </Stack>
      </FormControl>

      <Center mt={3}>
        <Button color={'white'}
              bg="blue.500"
              _hover={{
                textDecoration: 'none',
                color: textColor,
                bg: bgColor
              }} type="submit" colorScheme="blue">
          Enviar
        </Button>
      </Center>
    </Box>
  );
}