import {React, useState} from 'react'
import  Header from "../components/Header.js"
import TopBanner  from "../components/TopBanner.js"
import {TestimonialCard}  from "../components/Testimonials.js"
import Footer  from "../components/Footer.js"
import { VStack, Box, HStack, Stack } from "@chakra-ui/react"
import Sidebar from "../components/sidebar/Sidebar.js"
import RecentQuestions from "../components/forum/RecentQuestions.js"
import { useFirestoreContext } from '../FirestoreContext.js'
import { Button } from "@chakra-ui/react";
import { IconButton, Icon } from "@chakra-ui/react";
import { FaWhatsapp } from "react-icons/fa";

export function Home () {
  const { lastQuestions } = useFirestoreContext();
  const [posts, setPosts] = useState([]);
  const [ loaded, setLoaded ] = useState(0);


  if (!loaded) {
    lastQuestions().then(querySnapshot => {
        const preguntas = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setPosts(preguntas);
        setLoaded(1);
      }
    );

  }


  return (
    <Stack w={['100%', null, null, '80%']} direction={['column', null, null, 'column']}
    px={2}>
    <Header></Header>
    
    <Stack align="flex-start"  direction={['column', null, null, 'row']}> 
      <VStack width={['100%', null, null, '70%']} >
        <TopBanner></TopBanner>
        <Box  w={'100%'}>
        <TestimonialCard mt={20} as={Box} ></TestimonialCard>
        </Box>
      </VStack>
      <VStack margin={"auto"} width={["100%", "100%", "100%", '100%', "25%", "30%"]} overflow={'hidden'}>
         <Sidebar as={Stack} width={"100%"} margin="auto" ></Sidebar>
      </VStack>
    </Stack>

    { loaded ? <RecentQuestions posts={posts} ></RecentQuestions>: <></> }

    <IconButton
      aria-label="WhatsApp"
      icon={<Icon as={FaWhatsapp} boxSize={12} />} // Ajusta el valor de boxSize según tus necesidades
      position="fixed"
      bottom="20"
      right="4"
      color="	#25d366"
      size="lg"
      zIndex="9999"
      onClick={() => window.open('https://api.whatsapp.com/send/?phone=573002031988', '_blank')}
      isRound
    />
    <Footer></Footer>
    </Stack>
  )
}
