import { Card, CardHeader, CardBody, CardFooter, Image, Stack, Heading, Flex,
         Text, Divider, ButtonGroup, Button, Center, Container, SimpleGrid, Box} from '@chakra-ui/react'
import {React} from 'react';
import Header from "../components/Header.js";
import Footer  from "../components/Footer.js";
import { useAuthContext } from "../AuthContext";
import Reqs from '../components/Reqs.js';
import EditProfile from '../components/EditProfile.js';
import { useState, useEffect } from 'react';
import Notification from '../components/Notification.js';
import Empty from '../components/Empty.js';
import { useTranslation } from 'react-i18next';
import ChangePassword from '../components/ChangePassword.js'
import { useNavigate } from 'react-router-dom';

export function Profile() {

  const { t } = useTranslation();
  const { userData, isLoggedIn } = useAuthContext(); 
  const navigate = useNavigate();

    useEffect(() => {
      if (isLoggedIn===false) {navigate("/")}
    },[ isLoggedIn ]);
    const   [comps, setComps] = useState('edit'); 

    return (
        <Box w={"80%"}>
        <Header></Header>

      <Container maxW={'5x1'} py={12}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={1}>
          <Card py={6} w='250px' m={3}>
            <CardBody>
            <Center>
              <Image  
                src={userData.photoURL}                  
                alt='Green double couch with wooden legs'
                borderRadius='lg'
                rounded='full'
                boxSize='130px'                
              />
            </Center>  
            <Center>
              <Stack mt='6' spacing='3' textAlign="center">
                <Heading size='md'>{userData.displayName}</Heading>               
              </Stack>
            </Center>
            
              <Stack mt='6' >
              <Button variant='ghost' onClick={() => setComps('edit')} > 
              {t("profile.edit_profile")}
                    
                  </Button>      
                       
              </Stack>
              <Stack mt='2' >
              <Button variant='ghost' onClick={() => setComps('notification')}> 
              {t("profile.notifications")}                   
                  </Button>              
              </Stack>
              <Stack mt='2' >
              <Button variant='ghost' onClick={() => setComps('changePassword')}> 
              {t("profile.changePassword")}                   
                  </Button>              
              </Stack>
              </CardBody>                                                   
          </Card>
          <Flex w={'100%'}> 
             <Box w='100vw'>
             {(() => {switch (comps) {
              case 'request':return <Reqs />;

              case 'edit':return <EditProfile />;

              case 'notification':return <Notification/>;

              case 'changePassword':return <ChangePassword />;

              default:return <Empty />;
            }})()}
            </Box>
          </Flex>
        </SimpleGrid> 
      </Container>  

        <Footer></Footer>
        </Box>
      );
}
