import { useEffect, useState } from "react";
import { useAuthContext } from "../../AuthContext";
import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { useFirestoreContext } from "../../FirestoreContext";
import { useTranslation } from 'react-i18next';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Button } from '@chakra-ui/react';


function Cita({ cita, agendada, onAgendarClick }) {
    const bg = useColorModeValue("gray.200", "gray.800");
    


    return (
        <Box w="100%" bg={bg} m={5} p={3} shadow={"md"}>
            <Text>Fecha: {cita.date}</Text>
            <Text>Hora: {cita.time}</Text>
            <Text>Mensaje: {cita.message}</Text>
            <Text>Tipo de cita: {cita.type}</Text>
            <Text>Teléfono: {cita.phone}</Text>
            <Text>Correo: {cita.email}</Text>
            <Button size="sm" mt={2} onClick={onAgendarClick}>
                {agendada ? "Desagendar" : "Agendar"}
            </Button>
        </Box>
    );
}


export function Appointments() {
    const { subscribeToDoc } = useFirestoreContext();
    const { userData } = useAuthContext();
    const [citas, setCitas] = useState([]);
    const [citasAgendadas, setCitasAgendadas] = useState({});
    const { t, i18n } = useTranslation();


    useEffect(() => {
        subscribeToDoc(userData.uid, (val) => setCitas(val));
    }, [userData.uid]);


    const agendarCita = (citaId) => {
        setCitasAgendadas((prevCitasAgendadas) => ({
            ...prevCitasAgendadas,
            [citaId]: !prevCitasAgendadas[citaId],
        }));
    };


    const citasAgendadasIds = Object.keys(citas).filter(
        (citaId) => citasAgendadas[citaId]
    );
    const citasNoAgendadasIds = Object.keys(citas).filter(
        (citaId) => !citasAgendadas[citaId]
    );


    return (
        <Tabs isFitted>
            <TabList>
                <Tab>{t("appointments.unscheduledAppointments")}</Tab>
                <Tab>{t("appointments.scheduledAppointments")}</Tab>
            </TabList>  
            <TabPanels>
                <TabPanel>
                    {citasNoAgendadasIds.map((citaId, index) => (
                        <Cita
                            key={index}
                            cita={citas[citaId]}
                            agendada={citasAgendadas[citaId]}
                            onAgendarClick={() => agendarCita(citaId)}
                        />
                    ))}
                </TabPanel>
                <TabPanel>
                    {citasAgendadasIds.map((citaId, index) => (
                        <Cita
                            key={index}
                            cita={citas[citaId]}
                            agendada={citasAgendadas[citaId]}
                            onAgendarClick={() => agendarCita(citaId)}
                        />
                    ))}
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
}
