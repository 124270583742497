import { useFirestoreContext } from '../../FirestoreContext'
import { useAuthContext } from "../../AuthContext";
import { useEffect, useState } from "react";
import { getDownloadURL } from "firebase/storage"
import { Box, Button, FormControl, FormLabel, Input, Select, Textarea, Image, Stack, Center,Alert,
AlertIcon, useColorModeValue } from "@chakra-ui/react";
import { DropzoneArea } from "material-ui-dropzone";
import { useTranslation } from 'react-i18next';

export default function ExperiencesGallery (){
    const { t, i18n } = useTranslation();
    const firestore = useFirestoreContext();
    const [newPhotoURLs, setNewPhotoURLs] = useState([]);
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [files, setFiles] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const {fileUpload, updateProfileConfig } = useFirestoreContext();
    const { userData } = useAuthContext();
    const textColor = useColorModeValue('blue.500', 'blue.500');
    const bgColor = useColorModeValue('gray.200', 'gray.700');

    const handleImageUpload = async () => {
        if (files.length === 0) {
            console.log('No files selected');
            return;
          }
        setIsUploadingImage(true);
    
        // Subir cada imagen seleccionada
        const uploadedPhotoURLs = await Promise.all(
          files.map(async (file) => {
            const path = `experiences_gallery/${userData.uid}/${file.name}`;
            try {
              const downloadURL = await fileUpload(file, path);
              return downloadURL;
            } catch (error) {
              console.error('Error uploading image:', error);
              return null;
            }
          })
        );
    
        // Filtrar las URL de las imágenes que se subieron correctamente
        const validPhotoURLs = uploadedPhotoURLs.filter((url) => url !== null);
    
        // Actualizar el estado con las URL de las imágenes
        setNewPhotoURLs(validPhotoURLs);
    
        try {
            await updateProfileConfig(userData.uid, { experiencesGallery: validPhotoURLs });
            console.log('ProfileConfig updated successfully');
            setSuccessMessage('Images uploaded successfully!');
          } catch (error) {
            console.error('Error updating ProfileConfig:', error);
          }
      
    
        setIsUploadingImage(false);
      };
    
return (
    <FormControl id="fotos">
        <FormLabel textAlign={'center'}>{t("profile.select_files")}</FormLabel>
        <Stack direction="row" overflowX="auto" mt={3}>
          <DropzoneArea
              maxFileSize={3000000}
              filesLimit={3}
              acceptedFiles={['image/*']}
              dropzoneText={"Drag and drop an image here or click"}
              onChange={(filesvar) => {setFiles(filesvar)}}
              />
        </Stack>
        <Stack align={'center'}>
            <Button alignSelf={'center'} mt={5} onClick={handleImageUpload}
              bg={'blue.500'}
              color={'white'}
             _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
              color: textColor,
              bg: bgColor
            }}>{t("profile.loadImage")}</Button>
            {successMessage && ( // Renderizar el mensaje de éxito si existe
        <Alert status="success" mt={3}>
          <AlertIcon />
          {successMessage}
        </Alert>
      )}    
        </Stack>
      </FormControl>
)
}

