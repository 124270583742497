import React, { createContext, useContext, useState, useEffect} from 'react';
import { db, storage } from './firebase/credentials';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, query, orderBy, limit, getDocs, getDoc, where, doc, updateDoc } from "firebase/firestore"; 
import {
  ref,
  uploadBytes,
  getDownloadURL
} from "firebase/storage";


export const DirectoryContext = createContext();
export const useDirectoryContext = () => useContext(DirectoryContext);


export function DirectoryProvider ({ children }) {

  const pagesRef = collection(db, "profileConfig");



  function getDoctors(desease="Sistema_Digestivo-Hepatitis" , limite=10) {

    var q = query(collection(db, "profileConfig"),limit(limite),  where("diseasesYouTreat", "array-contains", desease));

    return getDocs(q)
    .then((querySnapshot) => {
      localStorage.setItem("searchTerm", desease);
      return querySnapshot.docs.map((doc) => doc.data());
    })
    .catch((error) => {
      throw error;
    });

  };


function getPremium(){
        const q = query(collection(db, "doctores"), where("isPremium", "==", true), limit(10));
        getDocs(q).then((resp) => {return (resp.docs)})
    };
    
    

  return (
    <DirectoryContext.Provider value={ { 
    getDoctors,
    getPremium,
     }}>
      { children }
    </DirectoryContext.Provider>
  );
}
