import { Box, Icon, Heading, Text, VStack, Avatar, Spacer, HStack} from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { MdDiamond, MdCheckCircleOutline } from "react-icons/md";

export default function ListResponses({ posts }) {
  return (
    <>
    {posts.map((pregunta, index) => (
      <Link to={`/question/${pregunta.question_id}`} key={index}>
      <Box 
          
          p={3}
          borderWidth="2px"
          borderRadius="md"
          mb={4}>
        <HStack spacing={3}>
          <VStack>
              <Avatar src={pregunta.author.image} alt={pregunta.author.name} />
              <HStack>
                          {pregunta.author.isPremium ? <Icon as={MdDiamond} w={6} h={6} color='blue.300' /> : <></> }
                          {pregunta.author.isVerified ?<Icon as={MdCheckCircleOutline} w={6} h={6} color='yellow.500' />: <></> }
              </HStack>
              <Text>{ pregunta.author.name }</Text>
          </VStack>
          <VStack align="start" spacing={2}>
            <Text size="xs">{pregunta.content.slice(0,30)}</Text>
            <Spacer />
            <Text fontSize="xs" color="gray.500">{new Date(pregunta.date.seconds * 1000).toLocaleDateString()}</Text>
          </VStack>
        </HStack>
      </Box>
      </Link>
    ))}
    </>
  );
}

