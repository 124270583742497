import {useState} from "react"
import {
    Box,
    Flex,
    Heading,
    Text,
    Stack,
    Grid,
    Avatar,
    useColorModeValue,
    Accordion,
    AccordionItem, 
    AccordionButton, 
    AccordionPanel,
    AccordionIcon,
    Center, 
  } from '@chakra-ui/react'
  import { useTranslation } from 'react-i18next';

import { useFirestoreContext } from '../../FirestoreContext'

import {NewOpinion} from "./NewOpinion"
import { OpinionsOnly } from "./OpinionsOnly"



export function Opinions(props) {
    const { t, i18n } = useTranslation();

    const {getEvals } = useFirestoreContext();

    const urlActual = window.location.href;   //Obtener el profileURL a usando la url actual
    const partesURL = urlActual.split('/');
    const ultimaParte = partesURL[partesURL.length - 1];
    const [opinions, setOpinions] = useState(null);

    if (opinions) {
        
    } else {
        getEvals(ultimaParte).then((res) => setOpinions(res));
    }



    const bg = useColorModeValue('gray.200', 'gray.800')


  return (
    <Box my={10}>
      <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem mb={5}>
                <h2>
                <AccordionButton bg={bg} rounded={"lg"}>
                    <Box as="span" flex='1' textAlign='left' >
                    {t("profileContent.opinions")}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    {opinions ?
                    <OpinionsOnly opinions={opinions} ></OpinionsOnly>
                    : <></>}
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                <AccordionButton bg={bg} rounded={"lg"}>
                    <Box as="span" flex='1' textAlign='left'>
                    {t("profileContent.newOpinion")}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <Center width={"70%"} mx={"auto"}>
                    {opinions ?
                        <NewOpinion nextVal={Object.keys(opinions).length} url={ultimaParte}></NewOpinion>
                    :   <NewOpinion nextVal={0} url={ultimaParte}></NewOpinion>}
                    </Center>

                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    </Box>
  )
};


  