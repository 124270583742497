import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  Img,
  Tag,
  useColorModeValue,
  Divider
} from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';


const ListHeader = ({ children, color }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2} color={color}>
      {children}
    </Text>
  );
};

export default function Footer() {
  const { t,  i18n } = useTranslation();
  const textColor = useColorModeValue('blue.500', 'blue.500');
  return (
    <Box
      color={useColorModeValue('gray.700', 'gray.200')}
      mt={20}>
      <Divider></Divider>
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={8}>
          <Stack align={'start'}>
            <ListHeader color={textColor}>{t("footer.social")}</ListHeader>
            <Link href={'https://www.facebook.com/BrocaHealth/'} display="flex" alignItems="center" p={1}>  
              <FaFacebook style={{ marginRight: '8px' }} />
              Facebook
            </Link>
            <Link href={'https://www.instagram.com/brocahealth?igshid=OGQ5ZDc2ODk2ZA%3D%3D'} display="flex" alignItems="center" p={1}>
              <FaInstagram style={{ marginRight: '8px' }} />
              Instagram
            </Link>
            <Link href={'https://www.linkedin.com/in/brocahealth/?originalSubdomain=co'} display="flex" alignItems="center" p={1}>
              <FaLinkedin style={{ marginRight: '8px' }} />
              LinkedIn
            </Link>
          </Stack>
          <Stack align={'center'}>
            <ListHeader color={textColor}>{t("footer.contact")}</ListHeader>
            <Text p={1}>{t("footer.chat")}</Text>
            <Text p={1}>{t("footer.openingHours")}</Text>
          </Stack>
          <Stack align={'end'}>
            <ListHeader color={textColor}>{t("footer.partners")}</ListHeader>
            <Link p={1} href={'https://www.brocalingua.com'}>Broca Lingua</Link>
            <Link p={1} href={'https://www.brocamedical.com'}>Broca Medical</Link>
            <Link p={1} href={'https://www.brocagroup.com'}>Broca Group</Link>
            <Link p={1} href={'/Faq'}>{t("faq.faqTittle")}</Link>
          </Stack>
        </SimpleGrid>
      </Container>
      <Box py={10}>
        <Flex
          align={'center'}
          _before={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            ml: 8,
          }}>


          <Img width={["100px", "200px", "250px", "300px"]} src='/images/logo.png'/>


        </Flex>
        <Text pt={6} fontSize={'sm'} textAlign={'center'}>
          © 2023 Broca Health. All rights reserved
        </Text>
      </Box>
    </Box>
  );
}