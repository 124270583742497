import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth, provider } from './firebase/credentials';
import { signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { db } from './firebase/credentials';
import { collection, addDoc, query, orderBy, limit, getDocs, getDoc, where, doc, setDoc} from "firebase/firestore"; 
import { updatePassword } from 'firebase/auth';
import { updateProfile } from 'firebase/auth';
import { reauthenticateWithCredential , EmailAuthProvider, } from 'firebase/auth';
export const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);

export function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({});
  const [status, setStatus] = useState("None");

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {

        const collectionRef = collection(db, "usuarios");
        const docRef = doc(collectionRef, user.uid);

        getDoc(docRef).then((data) => {
          if (data.data()) {                      // Revisa si encuentra el usuario en la colecion usuarios
            console.log("Encontrado en la coleccion usuarios");
            setUserData(data.data());
          }
          else {

            const collectionRef = collection(db, "profileConfig");
            const docRef = doc(collectionRef, user.uid);
            getDoc(docRef).then((data) => {
              // Si no se encontro en la coleccion usuario, revisamos la coleccion de doctores
              //console.log("Encontrado en la coleccion doctores", data.data());
              setUserData(data.data());    // Seteamos el usuario activo 
            }
            );
          }
        }
        )
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        setUserData({});
      }
    })
  },
    []);


  function login() { // Login y registro con boton de google, solo para usuarios, no doctores


    signInWithPopup(auth, provider).then(function (result) {   // Revisamos si el usuario es nuevo para crear el documento en la collecion usuarios
      const docRef = doc(collection(db, 'usuarios'), result.user.uid);

      getDoc(docRef).then((data) => {
        if (data.data()) {     // Si el usuario ya se encuentra en la base de datos, lee los datos
          console.log("Usuario encontrado", data.data());
          setUserData(data.data());
        }
        else {                 // Si no se encuentra, crea el documento para el usuario
          console.log("Crear usuario result:", result);
          const userData = {
            email: result.user.email,
            photoURL: result.user.photoURL,
            userType: "user",
            uid: result.user.uid
          };
          setDoc(docRef, userData);
          setUserData(result.user);
        }
      })

    }).catch(function (error) {

    });
  }

  function emailLogin(email, passw) {
    signInWithEmailAndPassword(auth, email, passw).then((result) => {
      console.log("email login success");
    }).catch((error) => {
      console.log(error);
    })
  }

  function logout() {
    signOut(auth);
  }

  function createAccount(email, password, type = "user", userData = {}) {
    console.log("Crear usuario con email-pass");
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
              setStatus("Success");
              const user = userCredential.user;
              userData.coverImage = "/images/banner.jpg"
              userData.email = user.email;
              userData.photoURL = user.photoURL;
              userData.userType = type;
              userData.isPremium = false;
              userData.isVerified = false;
              userData.uid = user.uid;
              userData.latitud = userData.latitud || "default_latitud";
              userData.longitud = userData.longitud || "default_longitud";
              const userId = user.uid;
              
              if (type==="doctor") {  // Revisa si es un doctor para crear el documento en la colecion correcta
                const docRef = doc(collection(db, 'doctores'), userId);  // documento para el perfil
                setDoc(docRef, userData);

                const docConfig = doc(collection(db, 'profileConfig'), userId); // Documento para la pagina del medico
                setDoc(docConfig, userData);

                const docEval = doc(collection(db, 'evaluaciones'), userId); // Documento para las calificaciones del medico
                setDoc(docEval, {});

                const docCita = doc(collection(db, 'citas'), userId); // Documento para las calificaciones del medico
                setDoc(docCita, {});

              } else {
                const docRef = doc(collection(db, 'usuarios'), userId);
                setDoc(docRef, userData);

              }
              

              const timeoutId = setTimeout(() => {navigate("/")}, 3400); // REDIRECT to HOME
              return () => clearTimeout(timeoutId);
              })

      .catch((error) => {

        setStatus(error.code.split("/")[1]);

      });
  }

  async function changePassword(newPassword) {
    try {
      const user = auth.currentUser;
      if (user) {
        await updatePassword(user, newPassword);
        console.log('Contraseña cambiada exitosamente');
      } else {
        console.error('No hay un usuario autenticado');
      }
    } catch (error) {
      console.error('Error al cambiar la contraseña:', error);
    }
  }
  async function reauthenticateUser(currentPassword) {
    try {
      const user = auth.currentUser;

      if (user) {
        const credential = EmailAuthProvider.credential(
          user.email,
          currentPassword
        );

        await reauthenticateWithCredential(user, credential);
        console.log('Reautenticación exitosa');
      } else {
        console.error('No hay un usuario autenticado');
      }
    } catch (error) {
      console.error('Error al reautenticar:', error);
      throw error;
    }
  }


  return (

    <AuthContext.Provider value={ {isLoggedIn, setIsLoggedIn, login, userData, emailLogin, logout, createAccount, status, setStatus, changePassword, reauthenticateUser, }}>
      { children }

    </AuthContext.Provider>
  );
};