import {
    Box,
    Flex,
    Stack,
    Heading,
    Text,
    Container,
    Input,
    Button,
    SimpleGrid,
    Avatar,
    AvatarGroup,
    useBreakpointValue,
    IconProps,
    Icon,
    Checkbox,
    useColorMode,
    useColorModeValue
  } from '@chakra-ui/react';

import TermConditionsModalPop from '../components/TermConditionsModal';
import { useAuthContext } from "../AuthContext";
import { useTranslation } from 'react-i18next';
import { Formik, setNestedObjectValues } from 'formik';
import { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from 'react-router-dom';
const avatars = [
    {
      name: 'Ryan Florence',
      url: 'https://bit.ly/ryan-florence',
    },
    {
      name: 'Segun Adebayo',
      url: 'https://bit.ly/sage-adebayo',
    },
    {
      name: 'Kent Dodds',
      url: 'https://bit.ly/kent-c-dodds',
    },
    {
      name: 'Prosper Otemuyiwa',
      url: 'https://bit.ly/prosper-baba',
    },
    {
      name: 'Christian Nwamba',
      url: 'https://bit.ly/code-beast',
    },
  ];
 
  export default function Signup() {

    const [isOpen, setIsOpen] = useState(false);
    const { isSignedUp } = false;
    
    const onClose = () => setIsOpen(false);
    const onOpen = () => setIsOpen(true);

    const toggleModalT = () => {
        console.log(isOpen);
        setIsOpen(!isOpen);
       };

    const { t } = useTranslation();
    const { createAccount, status, setStatus } = useAuthContext();
    const [username, setUsername] = useState("");
    const [passw, setPassw] = useState("");
    const [isVerified, setIsVerified] = useState(false);
    const { colorMode, toggleColorMode } = useColorMode();


    const handleCreateAccount = (event) => {
      event.preventDefault();
      if (isVerified) {
      createAccount(username, passw);
      } else {
        toast("Confirmar el captcha")
      }

    }

    const handleVerify = () => {
      setIsVerified(true);
    };

      
    useEffect(() => {
      console.log(`El valor de count ha cambiado a: ${status}`);

      switch (status){ 
        case "Success": 
          toast("Cuenta creada correctamente. Redireccionando en 3 segundos");
          setStatus("None");
          break;
        case "None":
          break;
        default:
          toast(status);
          break;
        

      }

      
    }, [status]);


    return (
      <Box position={'relative'} w={"80%"}>
        <ToastContainer />
        <Container
          as={SimpleGrid}
          maxW={'7xl'}
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 10, lg: 32 }}
          py={{ base: 10, sm: 20, lg: 32 }}>

          <Stack spacing={{ base: 10, md: 20 }}>
            <Heading
              lineHeight={1.1}
              fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '6xl' }}>
              { t("register.welcome") }
              <Text
                as={'span'}
                bgGradient="linear(to-l, red.400,red.200)">
              </Text>

            </Heading>
            <Stack direction={'row'} spacing={4} align={'center'}>
              <AvatarGroup>
                {avatars.map((avatar) => (
                  <Avatar
                    key={avatar.name}
                    name={avatar.name}
                    src={avatar.url}
                    size={{ base: 'md', md: 'lg'}}
                    position={'relative'}
                    zIndex={2}
                    _before={{
                      content: '""',
                      width: 'full',
                      height: 'full',
                      rounded: 'full',
                      transform: 'scale(1.125)',
                      bgGradient: 'linear(to-bl, red.400, red.400)',
                      position: 'absolute',
                      zIndex: -1,
                      top: 0,
                      left: 0,
                    }}
                  />
                ))}
              </AvatarGroup>
              <Text fontFamily={'heading'} fontSize={{ base: '4xl', md: '6xl' }}>
                +
              </Text>
              <Flex
                align={'center'}
                justify={'center'}
                fontFamily={'heading'}
                fontSize={{ base: 'sm', md: 'lg' }}
                bg={'gray.800'}
                color={'white'}
                rounded={'full'}
                minWidth={useBreakpointValue({ base: '44px', md: '60px' })}
                minHeight={useBreakpointValue({ base: '44px', md: '60px' })}
                position={'relative'}
                _before={{
                  content: '""',
                  width: 'full',
                  height: 'full',
                  rounded: 'full',
                  transform: 'scale(1.125)',
                  bgGradient: 'linear(to-bl, orange.400,red.400)',
                  position: 'absolute',
                  zIndex: -1,
                  top: 0,
                  left: 0,
                }}>
                YOU
              </Flex>
            </Stack>
          </Stack>
          <Stack
            rounded={'xl'}
            p={{ base: 4, sm: 6, md: 8 }}
            spacing={{ base: 8 }}
            maxW={{ lg: 'lg' }}>
            <Stack spacing={4}>
              <Heading
                color={'gray.800'}
                lineHeight={1.1}
                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
                { t("register.join") }
                <Text
                  as={'span'}
                  bgClip="text">
                  !
                </Text>
              </Heading>
              <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
              { t("register.text") }
              </Text>
            </Stack>

          <Box 
              as='form'
              onSubmit={handleCreateAccount}
              rounded={'lg'}
              boxShadow={'lg'}
              p={8}>
          <Stack spacing={4}>

            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input type="email" onChange={(e) => {setUsername(e.target.value)}}/>
            </FormControl>

            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input type="password" onChange={(e) => {setPassw(e.target.value)}}/>
            </FormControl>

            <Stack spacing={10}>
              
              <ReCAPTCHA
                margin= "-2px -2px -10px"
                theme={colorMode === 'light' ? "light" : "dark" }
                sitekey="6Le7QO0oAAAAAE29rebAPlCc29f6aJQVCs6Qb6Xk"
                onChange={handleVerify}
              />
              
              <Button variant='ghost' onClick={toggleModalT}> 
                    Ver términos y condiciones                    
                  </Button>
                  { isSignedUp ? toggleModalT() :
                    <TermConditionsModalPop isOpen={isOpen} onClose={toggleModalT} />
                  }
                  
            <Button 
                type='submit'
                color={'white'} bg="blue.500"  _hover={{textDecoration: 'none', color: useColorModeValue('blue.500', 'blue.500'), bg: useColorModeValue('gray.200', 'gray.700')}}>

                { t("register.register") }
              </Button>
            </Stack>
          </Stack>
        </Box>



          </Stack>
        </Container>

        <Blur
          position={'absolute'}
          top={-10}
          left={-10}
          style={{ filter: 'blur(70px)' }}
        />
      </Box>
    );
  }
  
  export const Blur = (props) => {
    return (
      <Icon
        width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
        zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
        height="560px"
        viewBox="0 0 528 560"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <circle cx="71" cy="61" r="111" fill="#F56565" />
    
        <circle cy="291" r="139" fill="#ED64A6" />
        <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
  

        <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
      </Icon>
    );
  };