import React, { useState, useEffect } from 'react';
import {
  Heading,
  Avatar,
  Box,
  Center,
  Image,
  Flex,
  Text,
  Stack,
  Button,
  useColorModeValue,
  Textarea,
  Container,
  Input,
} from '@chakra-ui/react';
import { useAuthContext } from "../../AuthContext"
import { useTranslation } from 'react-i18next';
import { useFirestoreContext } from '../../FirestoreContext';

export function EditProfile(){
    const { t, i18n } = useTranslation();
    const { userData } = useAuthContext();
    const [isHovered, setIsHovered] = useState(false);
    const [newDisplayName, setNewDisplayName] = useState(userData.displayName || '');
    const [newRethus, setNewRethus] = useState(userData.rethus || '');
    const [newSpeciality, setNewSpeciality] = useState(userData.speciality || '');
    const [newPhotoURL, setNewPhotoURL] = useState(userData.photoURL || '');
    const [selectedImage, setSelectedImage] = useState(null);
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const { updateUserInfo, fileUpload } = useFirestoreContext();
    const textColor = useColorModeValue('blue.500', 'blue.500');
    const bgColor = useColorModeValue('gray.200', 'gray.700');
   

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
          const path = `profile_images/${userData.uid}/${file.name}`;
    
          try {
            const downloadURL = await fileUpload(file, path);
            setNewPhotoURL(downloadURL);
            setSelectedImage(file);
            setIsUploadingImage(false);
          } catch (error) {
            console.error('Error uploading image:', error);
            setIsUploadingImage(false);
          }
        }
      };

    const handleSaveChanges = async () => {
        try {
            console.log('Updating profile with uid:', userData);

          if (!userData || !userData.uid) {
            console.error('userData or UID is undefined');
            return;
          }
    
          const usertype = userData.userType; // Assuming you have a 'usertype' field in your user data
    
          if (selectedImage) {
            // Upload the image to Firebase Storage and get the download URL
            const path = `profile_images/${userData.uid}/${selectedImage.name}`;
            const downloadURL = await fileUpload(selectedImage, path);
    
            console.log('Uploading image. New downloadURL:', downloadURL);
    
            // Update user information based on usertype
            await updateUserInfo(userData.uid, {
                displayName: newDisplayName,
                photoURL: downloadURL,
                speciality: newSpeciality,
                rethus: newRethus,
              }, usertype);
    
            // Update the local state if the update is successful
            setNewPhotoURL(downloadURL);
            setSelectedImage(null);
          } else {
            // Update user information based on usertype
            await updateUserInfo(userData.uid, {
                displayName: newDisplayName,
                photoURL: newPhotoURL || '',
                speciality: newSpeciality,
                rethus: newRethus,
              }, usertype);
          }
    
          // Handle success (e.g., show a success message)
          console.log('Profile updated successfully');
        } catch (error) {
          // Handle error (e.g., show an error message)
          console.error('Error updating profile:', error);
        }
      };

    const handleNameChange = (e) => {
        setNewDisplayName(e.target.value);
      };

    const handleSpecialityChange = (e) => {
        setNewSpeciality(e.target.value);
      };

    const handleRethusChange = (e) => {
        setNewRethus(e.target.value);
      };

    return (
      <Stack py={3} w={"100%"}>
      <Box
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}
        textAlign={'center'}
      >
        <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'} m={6}  >
          {t("profile.edit_profile")}
        </Heading>
        <Flex justify={'center'} mt={5}>
          <label htmlFor="profile-image-upload" style={{ position: 'relative', cursor: 'pointer' }}>
            <div
              style={{
                width: '200px', // Tamaño fijo del contenedor de la imagen
                height: '200px', // Tamaño fijo del contenedor de la imagen
                overflow: 'hidden',
                borderRadius: '50%',
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Avatar
                size={'full'}
                src={newPhotoURL || userData.photoURL}
                alt={'Author'}
                css={{
                  border: '2px solid #00aad0',
                  width: '100%', // Ajustar la imagen al tamaño del contenedor
                  height: '100%', // Ajustar la imagen al tamaño del contenedor
                }}
              />
              {isHovered && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.5)', // Cambia el color de fondo y la opacidad
                    overflow: 'hidden',
                    opacity:'0.8',
                    borderRadius: '50%',
                  }}
                >
                  <Image
                    src="https://cdn.icon-icons.com/icons2/3217/PNG/512/edit_user_avatar_profile_person_icon_196565.png"
                    alt="Superpuesta"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
              )}
            </div>
            <Input
              id="profile-image-upload"
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleImageUpload}
            />
          </label>
        </Flex>

        <Box p={6}>
          <Stack spacing={0} align={'left'} mb={5}>
            <Text mb='8px'>{t("profile.name")}</Text>
            <Input
              placeholder={userData.displayName}
              size='sm'
              value={newDisplayName} // Bind value to the newDisplayName state
              onChange={handleNameChange} // Add onChange handler to update newDisplayName
            />
          </Stack>
          <Stack spacing={0} align={'left'} mb={5}>
            <Text mb='8px'>{t("profile.rethus")}</Text>
            <Input
              placeholder={userData.rethus}
              size='sm'
              value={newRethus}
              onChange={handleRethusChange}
            />
          </Stack>
          <Stack spacing={0} align={'left'} mb={5}>
            <Text mb='8px'>{t("profile.speciality")}</Text>
            <Input
              placeholder={userData.speciality}
              size='sm'
              value={newSpeciality}
              onChange={handleSpecialityChange}
            />
          </Stack>
          <Button
            w={'full'}
            mt={8}
            bg={useColorModeValue('#00aad0', 'gray.900')}
            color={'white'}
            rounded={'md'}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
              color: textColor,
              bg: bgColor
            }}
            onClick={handleSaveChanges}
          >
            {t("profile.save_changes")}
          </Button>
        </Box>
      </Box>
    </Stack>
    );

};