import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Stack,
  useBreakpointValue,
  IconButton, useColorModeValue
} from '@chakra-ui/react';
import { useAuthContext } from "../../AuthContext";
import Slider from 'react-slick';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { useTranslation } from 'react-i18next';

export default function Gallery() {
  const { isLoggedIn } = useAuthContext();
  const { userData } = useAuthContext();
  const [slider, setSlider] = React.useState(null);
  const [images, setImages] = useState([]);
  const top = useBreakpointValue({ base: '90%', md: '50%' })
  const side = useBreakpointValue({ base: '30%', md: '40px' })
  const { t, i18n } = useTranslation();
  const textColor = useColorModeValue('blue.500', 'blue.500');
  const bgColor = useColorModeValue('gray.200', 'gray.700');

  useEffect(() => {
    if (isLoggedIn) {
      // Use userData directly, assuming that 'experiencesGallery' is an array of image URLs
      if (userData && userData.experiencesGallery) {
        setImages(userData.experiencesGallery);
      }
    }
  }, [isLoggedIn, userData]);

  const settings = {
    dots: true,
    arrows: true,
    fade: false,
    infinite: true,
    autoplay: false,
    speed: 2000,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnFocus: false,
    pauseOnHover: false,
  };
  

  const sliderRef = React.useCallback((node) => {
    if (node !== null) {
      setSlider(node);
    }
  }, []);

  if (!isLoggedIn) {
    return (
      <Box textAlign="center" p={8}>
        <Text fontSize="xl" fontWeight="bold">
          {t("profileContent.loginRequiredGallery")}
        </Text>
      </Box>
    );
  }

  return (
    <Box position={'relative'} height={'100%'} width={'full'} overflow={'hidden'}>
      <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
      <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
      <IconButton
          aria-label="left-arrow"
          variant="ghost"
          position="absolute"
          left={side}
          top={top}
          bg={'blue.500'}
              color={'white'}
             _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
              color: textColor,
              bg: bgColor}}
          transform={'translate(0%, -50%)'}
          zIndex={2}
          onClick={() => slider?.slickPrev()}>
          <BiLeftArrowAlt size="40px" />
        </IconButton>
        {/* Right Icon */}
        <IconButton
          aria-label="right-arrow"
          variant="ghost"
          position="absolute"
          right={side}
          bg={'blue.500'}
              color={'white'}
             _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
              color: textColor,
              bg: bgColor}}
          top={top}
          transform={'translate(0%, -50%)'}
          zIndex={2}
          onClick={() => slider?.slickNext()}>
          <BiRightArrowAlt size="40px" />
        </IconButton>

      <Slider {...settings} ref={sliderRef}>
        {images.map((image, index) => (
          <Box
            key={index}
            height={'550px'}
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundImage={`url(${image})`}
          ></Box>
          
        ))}
      </Slider>
    </Box>
  );
}
