import { Box, Button, FormControl, FormLabel, Input, Select, Textarea, Image, Stack, Center, chakra } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFirestoreContext } from '../../FirestoreContext'
import { useAuthContext } from "../../AuthContext";
import { Timestamp } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { RichTextEditor } from "../TextEditor";
import { DropzoneArea } from "material-ui-dropzone";
import { getDownloadURL } from "firebase/storage"
import { useTranslation } from 'react-i18next';

export function CreateArticle() {
  const navigate = useNavigate();
  const { createArticle, fileUpload } = useFirestoreContext();
  const { userData } = useAuthContext();
  const [titulo, setTitulo] = useState("");
  const [contenido, setContenido] = useState("");
  const [categoria, setCategoria] = useState("");
  const [imagenes, setImagenes] = useState([]);
  const [files, setFiles] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => console.log(contenido), []);

  const handleSubmit = (event) => {
    event.preventDefault();

    let urls = [];
const uploadPromises = files.map((file) => {
  return fileUpload(file, "questions").then((snapshot) => {
                                                            console.log(snapshot);
                                                            urls.push(snapshot);
                                                          })
});

Promise.all(uploadPromises)
  .then(() => {
    var data = {
      title: titulo,
      content: contenido,
      author: {
        name: userData.displayName,
        image: userData.photoURL,
        userType: userData.userType,
        // TODO: Es mejor que al leer la pregunta de la db, se lean los datos actualizados del usuario, en lugar de escribirlos aca 
      },
      date: Timestamp.now(),
      images: urls,
    };

    if (userData.userType==="doctor"){
      data.isVerified= userData.isVerified;
      data.isPremium= userData.isPremium
    }
    createArticle(data)
      .then((res) => {
        console.log(res.id);
        navigate(`/article/${res.id}`);
      })
      .catch((error) => {
        console.error('Error al crear la pregunta:', error);
      });
  })
  .catch((error) => {
    console.error('Error al subir archivos:', error);
  });

  };

  return (
    <Box 
      as="form" 
      onSubmit={handleSubmit} 
      p={5} 
      border="1px" 
      borderColor="gray.200" 
      borderRadius="md" 
      shadow="sm"
      mx="auto"
      my={5}
    >
    <chakra.h2 fontSize="2xl" fontWeight="700" my={5}>
    {t("articles.createArticle")}
    </chakra.h2>
      <FormControl id="titulo">
        <FormLabel>{t("articles.title")}</FormLabel>
        <Input type="text" value={titulo} onChange={(e) => setTitulo(e.target.value)} />
      </FormControl>

      <FormControl id="contenido">
        <FormLabel>{t("articles.content")}</FormLabel>
        <RichTextEditor value={contenido} onChange={setContenido} />
      </FormControl>

      <FormControl id="categoria">
        <FormLabel>{t("articles.category")}</FormLabel>
        <Select placeholder="Selecciona categoría" value={categoria} onChange={(e) => setCategoria(e.target.value)}>
          <option value="categoria1">Categoría 1</option>
          <option value="categoria2">Categoría 2</option>
          <option value="categoria3">Categoría 3</option>
        </Select>
      </FormControl>

      <FormControl id="fotos">
        <FormLabel>{t("articles.uploadFile")}</FormLabel>
        <Stack direction="row" overflowX="auto" mt={3}>
          <DropzoneArea
              maxFileSize={3000000}
              filesLimit={3}
              acceptedFiles={['image/*']}
              dropzoneText={"Drag and drop an image here or click"}
              onChange={(filesvar) => {setFiles(filesvar)}}
              />
        </Stack>
      </FormControl>

      <Center mt={3}>
        <Button type="submit" colorScheme="blue">
        {t("articles.sendArticle")}
        </Button>
      </Center>
    </Box>
  );
}