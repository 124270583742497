// Import the functions you need from the SDKs you need
import { initializeApp, FirebaseApp, firebase } from "firebase/app";
import { getAuth, GoogleAuthProvider} from "firebase/auth"
import { getFirestore }  from "firebase/firestore"
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyA6OTeCTgeHUH-ESrWhJDq1iifswtgK96o",
  authDomain: "broca-377418.firebaseapp.com",
  projectId: "broca-377418",
  storageBucket: "broca-377418.appspot.com",
  messagingSenderId: "871926171951",
  appId: "1:871926171951:web:c5dafd31bc22f5a17679da",
  measurementId: "G-F66X9Z0RMP"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const provider = new GoogleAuthProvider();
export const storage = getStorage(firebaseApp);

