import { Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalContent,
  ModalHeader,
  ModalCloseButton, useDisclosure, ModalOverlay, Center, Text, useColorModeValue } from "@chakra-ui/react";
  import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    InputGroup,
    Input,
    InputRightElement,
    Box
  } from '@chakra-ui/react';
import { EmailIcon, UnlockIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import GoogleButton from 'react-google-button';
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import { useAuthContext} from "../AuthContext";
import { Link } from "react-router-dom";


export default function ModalLogin({isOpen, onClose}) {
  const { t } = useTranslation();
  const { login, emailLogin } = useAuthContext();
  const [username, setUsername] = useState("");
  const [passw, setPassw] = useState("");

  const handleEmailLogin = () => {
    emailLogin(username, passw);
  }

  return (
    <Box>
      <Modal 
      onClose={onClose}
      isOpen={isOpen} isCentered
      motionPreset='flip'>

        <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(90deg)'
         />
        <ModalContent>
          <ModalHeader>
            <Center>
              <h2> { t("modallogin.title") } </h2>
            </Center>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>

          <FormControl>
    <FormLabel>Email</FormLabel>
    <FormErrorMessage>Error message</FormErrorMessage>
    <InputGroup>
      <Input id="emailinput" onChange={(e) => {setUsername(e.target.value)}} />
      <InputRightElement>
        <EmailIcon name="email" />
      </InputRightElement>
    </InputGroup>
    <FormLabel>Password</FormLabel>
    <InputGroup>
      <Input type="password" id="passinput" onChange={(e) => {setPassw(e.target.value)}}></Input>
      <InputRightElement>
        <UnlockIcon name="password" />
      </InputRightElement>
    </InputGroup>
    <Center mt={5}>
      <Button rightIcon={<ArrowForwardIcon />} color={'white'} bg="blue.500"  _hover={{textDecoration: 'none', color: useColorModeValue('blue.500', 'blue.500'), bg: useColorModeValue('gray.200', 'gray.700')}} size="md" onClick={handleEmailLogin}>
      
      {t("modallogin.title")}
      </Button>
    </Center>
  </FormControl>


            <Center mt={15}>
            <GoogleButton label= { t("modallogin.continue_google")} onClick={ login }></GoogleButton>
            </Center>
          </ModalBody>
          <ModalFooter>
            <Center>
              <Link to={"/register"}>
               { t("modallogin.footer", {here: " Here"} ) } 
              </Link>
            </Center>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </Box>
  )
}



