import React from 'react'
import  Header from "../components/Header.js"
import TopBanner  from "../components/TopBanner.js"
import TestimonialCard  from "../components/Testimonials.js"
import Footer  from "../components/Footer.js"
import { useTranslation } from 'react-i18next'
import { VStack, Box, HStack } from "@chakra-ui/react"
import {RegisterDoctor} from "../components/registerDoctor/RegisterDoctor"

export function RegisterDoctors () {
  const { t,  i18n } = useTranslation();
  return (
    <Box w={"80%"}>
    <Header></Header>
    
    
      <VStack margin={"auto"}>
        <RegisterDoctor width={"100%"} margin={0}/>
      </VStack>

    
    
    <Footer></Footer>
    </Box>
  )
}
