import {React, useState, useEffect} from 'react'
import  Header from "../components/Header.js"
import { useFirestoreContext } from '../FirestoreContext'
import Footer  from "../components/Footer.js"
import ListResponses from "../components/forum/ListResponses.js"
import  ShowQuestion  from '../components/forum/ShowQuestion.js'
import { Carousel } from '../components/Carousel.js'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import { Heading, Spacer, Button, Box, Center, useColorModeValue} from '@chakra-ui/react'
import { Formulario } from '../components/forum/NewResponseForm.js'
import { useAuthContext } from "../AuthContext";

export function Question () {
  const { t,  i18n } = useTranslation();
  const { getQuestion, getResponses } = useFirestoreContext();
  const [posts, setPosts] = useState([]);
  const [ loaded, setLoaded ] = useState({});
  const [ questionData, setQuestionData ] = useState(0);
  const [ option, setOption ] = useState("forum");
  const { id } = useParams();
  const [openForm, setOpenForm] = useState(false);
  const [update, setUpdate] = useState(false);
  const { isLoggedIn } = useAuthContext(); 
  const textColor = useColorModeValue('blue.500', 'blue.500');
  const bgColor = useColorModeValue('gray.200', 'gray.700');


  function updateResponses(){
    setUpdate(!update);
    setOpenForm(!openForm);
    console.log(openForm);
  }

  useEffect(() => {
  getResponses(id).then(querySnapshot => {
        const respuestas = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setPosts(respuestas);
      }
    )},
    [update]
  )
  
  useEffect(() => {
  getQuestion(id).then((res) => 
    {console.log("GET QUESTION", res.data());
      setQuestionData(res.data());
      setLoaded(!loaded);
    }).catch((error) => {return (<></>)})
},[])
  return (

    <Box w={"80%"}>
    <Header></Header>
    <Box 
      borderWidth="2px"
      borderRadius="md">

    {questionData ? <ShowQuestion question={ questionData }></ShowQuestion>: <></>}
    {questionData ? 
    <Center>
    <Carousel images={questionData.images} isLoggedIn={isLoggedIn}></Carousel>
    </Center>

    : <></>}

    

    <Center m={20}>
      { !openForm ?
        <Button
        color={'white'}
        bg="blue.500"
        _hover={{
          textDecoration: 'none',
          color: textColor,
          bg: bgColor
        }}
            onClick={() => setOpenForm(!openForm)}
          >
            Responder
        </Button> : <></>
      }
    </Center>
    <Box margin={4}>
      {openForm ? <Formulario question_id={id} updateResponses={updateResponses}></Formulario> : null}
    </Box>

    </Box>
    <Spacer />
    <Heading mb={4} size={'sm'} mt={4}> Respuestas </Heading>
    <ListResponses posts={posts}></ListResponses>
    <Footer></Footer>
    </Box>

  )
}
