import React from 'react';
import { Checkbox, CheckboxGroup, Stack, Box, useColorModeValue, Heading,
Text, StackDivider, Button
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';


export default function Notificaction() {
  const { t,  i18n } = useTranslation();

  function changeLang(lang) {
      i18n.changeLanguage(lang);
    }
  return (
    <Stack>
      
      <Box
         py={6} 
        maxW={'600px'}
        w={'600px'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}>

          
          

          <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'} m={6} >
          {t("profile.edit_notifications")}
          </Heading>

          
          <Text py={2} m={6}>
          {t("profile.send_mails")}
          </Text>
          <Checkbox marginLeft={5} defaultChecked
           fontSize={'2xl'} fontWeight={300} fontFamily={'body'} >
            {t("profile.comment_mypost")}</Checkbox>
            <Checkbox marginLeft={5} defaultChecked
           fontSize={'2xl'} fontWeight={300} fontFamily={'body'} >
            {t("profile.comment_otherpost")}</Checkbox>
            <Checkbox marginLeft={5} defaultChecked
           fontSize={'2xl'} fontWeight={300} fontFamily={'body'} >
            {t("profile.doctor_receivepost")}</Checkbox>
            
           
            
            <Stack divider={<StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />}>
                <Text py={2} m={6}>
                {t("profile.notifications")} BrocaHealt:
                </Text>
                <Checkbox marginLeft={5} defaultChecked
                fontSize={'2xl'} fontWeight={300} fontFamily={'body'} >
                  {t("profile.weekly_neews")}</Checkbox>
            
            </Stack>

            <Stack divider={<StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />}>
                <Text py={2} m={6}>
                {t("profile.monthly_lottery")}
                </Text>
                <Checkbox marginLeft={5} defaultChecked
                fontSize={'2xl'} fontWeight={300} fontFamily={'body'} >
                  {t("profile.lottery")}</Checkbox>
            
            </Stack>

            <Button
            
            w={'full'}
            mt={5}
            p={3}
            bg={useColorModeValue('#00aad0', 'gray.900')}
            color={'white'}
            rounded={'md'}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
            }}            
            >
            {t("profile.save_changes")}
          </Button>
        </Box>
    </Stack>
  )
}