import { Box, Button, FormControl, FormLabel, Input, Textarea, Center, useColorModeValue, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useFirestoreContext } from '../../FirestoreContext';
import { useAuthContext } from "../../AuthContext";
import { Stars } from "./Stars";
import { Thanks } from "../Thanks";
import { useTranslation } from 'react-i18next';

export function NewOpinion({url, nextVal}) {
  const { createEval } = useFirestoreContext();
  const { userData, isLoggedIn } = useAuthContext();
  const [rate, setRate] = useState("");
  const [titulo, setTitulo] = useState("");
  const [contenido, setContenido] = useState("");
  const [newOpinion, setNewOpinion] = useState(true);
  const { t, i18n } = useTranslation();
  const bgColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('blue.500', 'blue.500');

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const data = {};
    data[nextVal + 1] = {
      author: userData.displayName,
      image: userData.photoURL,
      content: contenido,
      title: titulo,
      rate: rate + 1,
    };
    createEval(url, data).then(() => setNewOpinion(false));
  };

  if (!isLoggedIn) {
    return (
        <Box textAlign="center" p={8}>
          <Text fontSize="xl" fontWeight="bold">
            {t("profileContent.loginRequiredNewOpinion")}
          </Text>
        </Box>
    )
  }

  return (
    newOpinion ? (
      <Box 
        w="100%"   
        alignItems="center"  
        as="form" 
        onSubmit={handleSubmit} 
        p={5} 
        border="1px" 
        borderColor="gray.200" 
        borderRadius="md" 
        shadow="sm"
        my={5}
      >
        <FormControl id="titulo">
          <FormLabel>{t("profileContent.opinionTitle")}</FormLabel>
          <Input type="text" value={titulo} onChange={(e) => setTitulo(e.target.value)} />
        </FormControl>

        <FormControl id="contenido">
          <FormLabel>{t("profileContent.opinionContent")}</FormLabel>
          <Textarea type="text" value={contenido} onChange={(e) => setContenido(e.target.value)} />
        </FormControl>

        <FormControl id="contenido">
          <Center>
            <FormLabel>{t("profileContent.opinionRating")}</FormLabel>
          </Center>
          <Center>
            <Stars value={contenido} Callback={(e) => setRate(e)}></Stars>
          </Center>
        </FormControl>

        <Center mt={3}>
          <Button
            type="submit"  
            color={'white'}
            bg={'blue.500'}
            rounded={'md'}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
              color: textColor,
              bg: bgColor
            }}
          >
            {t("profileContent.sendOpinion")}
          </Button>
        </Center>
      </Box>
    ) : (
      <Thanks></Thanks>
    )
  );
}
