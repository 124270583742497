import {
  Heading,
  Avatar,
  Box,
  Center,
  Image,
  Flex,
  Text,
  Stack,
  Button,
  useColorModeValue,
  Icon,
  HStack,
  VStack,
  Tag
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';

import { MdDiamond, MdTranslate, MdLocationCity, MdWorkspacePremium, MdShield } from 'react-icons/md'
import { Link } from "react-router-dom";

export function VerifiedDoctorCard({ doctorData }) {
  const { t, i18n } = useTranslation();
  return (
    <Center py={2} w="100%">
      <Box
        w={"100%"}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}>
        <Image
          h={'120px'}
          w={'full'}
          src={doctorData.coverImage}
          objectFit="cover"
          alt="#"
        />
        <Flex justify={'center'} mt={-12}>
          <Avatar
            size={'xl'}
            src={doctorData.photoURL}
            css={{
              border: '2px solid white',
            }}
          />
        </Flex>

        <Box p={3}>
          <Stack spacing={0} align={'center'} mb={1}>
 
              <Heading fontSize={'xl'} fontWeight={500} fontFamily={'body'}>
                {doctorData.displayName}
              </Heading>


              <Text color={'gray.500'}>{doctorData.speciality}</Text>
              <Text color={'gray.500'}><Icon as={MdLocationCity} w={"20px"} h={"20px"} mx={1} color='blue.300' />{doctorData.city}</Text>
              <HStack spacing={1}>
            <Icon as={MdTranslate} w={"20px"} h={"20px"} mx={0} color='blue.300' />
            { doctorData.languages ? doctorData.languages.map((lang, key) =>
            <Tag key={key} size={"sm"} variant='subtle' colorScheme='blue'> {lang} </Tag> ) : <></>
            }
            </HStack>
          </Stack>

        
          <Link to={"/doctor/" + doctorData.profileURL}>
          <Button
            
            w={'full'}
            mt={8}
            bg={useColorModeValue('blue.500', 'blue.500')}
            color={'white'}
            rounded={'md'}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
              bg: useColorModeValue('gray.200', 'gray.700'),
              textDecoration: 'none', color: useColorModeValue('blue.500', 'blue.500')
            }}>
            {t("sideBar.watchButton")}
          </Button>
          </Link>
        </Box>
      </Box>
    </Center>
  )
}