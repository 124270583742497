import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box, Heading, Text, Stack, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import  Header from "../components/Header.js"
import Footer  from "../components/Footer.js"
import Slider from 'react-slick'



export default function PrivacyPolicy () {
    const { t } = useTranslation();


    return (

        <Box w={"85%"} textAlign={'center'}>
            <Header></Header>
                <Box mt={20}>
                    <Heading color={'blue.500'}>{t("privacyPolicy.title")}</Heading>
                </Box>
                <Box>
                <Text mt={2}>{t("privacyPolicy.description")}</Text>
                </Box>
                <Box mt={2}>
                    <Heading mt={20}>{t("privacyPolicy.principles")}</Heading>
                </Box>
                <Box mt={20}>
                    <Text as='b'>{t("privacyPolicy.legality")}</Text>
                    <Text>{t("privacyPolicy.legalityText")}</Text>
                </Box>
                <Box mt={20}>
                    <Text as='b'>{t("privacyPolicy.purpose")}</Text>
                    <Text>{t("privacyPolicy.purposeText")}</Text>
                </Box>
                <Box mt={20}>
                    <Text as='b'>{t("privacyPolicy.freedom")}</Text>
                    <Text>{t("privacyPolicy.freedomText")}</Text>
                </Box>
                <Box mt={20}>
                    <Text as='b'>{t("privacyPolicy.truthOrQuality")}</Text>
                    <Text>{t("privacyPolicy.truthOrQualityText")}</Text>
                </Box>
                <Box mt={20}>
                    <Text as='b'>{t("privacyPolicy.transparency")}</Text>
                    <Text>{t("privacyPolicy.transparencyText")}</Text>
                </Box>
                <Box mt={20}>
                    <Text as='b'>{t("privacyPolicy.security")}</Text>
                    <Text>{t("privacyPolicy.securityText")}</Text>
                </Box>
                <Box mt={20}>
                    <Text as='b'>{t("privacyPolicy.confidentiality")}</Text>
                    <Text>{t("privacyPolicy.confidentialityText")}</Text>
                </Box>
                <Box mt={20}>
                    <Text as='b'>{t("privacyPolicy.consent")}</Text>
                    <Text>{t("privacyPolicy.consentText")}</Text>
                </Box>
                <Text mt={2}>{t("privacyPolicy.text")}</Text>
                <Box mt={20}>
                    <Text as='b'>{t("privacyPolicy.dataController")}</Text>
                    <Text>{t("privacyPolicy.dataControllerText")}</Text>
                </Box>
                <Box mt={20}>
                    <Text as='b'>{t("privacyPolicy.purposeExplanations")}</Text>
                    <Text>{t("privacyPolicy.purposeExplanationsText")}</Text>
                </Box>
                <Box mt={20}>
                    <Text as='b'>{t("privacyPolicy.modeOfUse")}</Text>
                    <Text>{t("privacyPolicy.modeOfUseText")}</Text>
                </Box>
                <Box mt={20}>
                    <Text as='b'>{t("privacyPolicy.scopeOfData")}</Text>
                    <Text>{t("privacyPolicy.scopeOfDataText")}</Text>
                </Box>
                <Box mt={20}>
                    <Text as='b'>{t("privacyPolicy.contact")}</Text>
                    <Text>{t("privacyPolicy.contactText")}</Text>
                </Box>
            <Footer></Footer> 
        </Box>
    
    )
}