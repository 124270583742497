import './styles/App.css';
import { React, useEffect } from 'react';
import { AuthProvider } from './AuthContext';
import { DirectoryProvider } from './DirectoryContext';
import { FirestoreProvider } from './FirestoreContext';
import { Box } from '@chakra-ui/react';
import { BrowserRouter, Route, Routes, useNavigate} from 'react-router-dom';
import { Home } from "./pages/Home.js"
import i18n from './Translation';
import { I18nextProvider } from 'react-i18next';
import Signup from './pages/Signup';
import Forum from './pages/Forum';
import Faq from './pages/Faq.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js';
import  {Profile} from './pages/Profile.js';
import { Question } from './pages/Question';
import { DashboardPage } from './pages/Dashboard'
import { RegisterDoctors } from './pages/RegisterDoctorsPage'
import { DoctorPublicProfile } from './pages/DoctorPublicProfile'
import { Article } from "./pages/Article"
import  Directory  from './pages/Directory'
import { Articles } from './pages/Articles'
import Service360 from './pages/Service360.jsx'
import About from './pages/About.jsx';
import { App as CapacitorApp } from '@capacitor/app';

function AppContainer() {

  CapacitorApp.addListener('backButton', ({canGoBack}) => {
    if(!canGoBack){
      CapacitorApp.exitApp();
    } else {
      window.history.back();
    }
  });

  
  const navigate = useNavigate()

  useEffect(() => {
    const handleBackButton = () => {
      // Realiza acciones específicas al presionar el botón de retroceso
      console.log('Botón de retroceso presionado');
      navigate(-1);
    };

    window.addEventListener('backbutton', handleBackButton);

    return () => {
      window.removeEventListener('backbutton', handleBackButton);
    };
  }, []);


  return (

    <I18nextProvider i18n={i18n}>
    <AuthProvider>
    <FirestoreProvider>
    <DirectoryProvider>
    <Box h={"100%"}
      w={"100%"}
      mt={{base: "15px", md: "20px"}}
      display="flex"             // Establece el contenedor como un contenedor flexible
      justifyContent="center"   // Centra horizontalmente los elementos
      alignItems="center"       // Centra verticalmente los elementos
      >

    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Signup />} />
        <Route path="/directory" element={<Directory />} />
        <Route path="/article/:id" element={<Article />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/question/:id" element={<Question />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/signup-doctor" element={<RegisterDoctors />} />
        <Route path="/doctor/:profileURL" element={<DoctorPublicProfile />} />
        <Route path="/360service" element={<Service360 />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/Privacypolicy" element={<PrivacyPolicy />} />
      </Routes>

    </Box>
    </DirectoryProvider>
    </FirestoreProvider>
    </AuthProvider>
    </I18nextProvider>
  );
};


export default AppContainer;
