import React from 'react'
import {
  Box,
  Stack,
  Heading,
  Text,
  Image,
  SimpleGrid,
  useBreakpointValue, 
  Link,
  IconButton,
  useColorModeValue
} from '@chakra-ui/react';
import  Header from "../components/Header.js"
import Footer  from "../components/Footer.js"
import { useTranslation } from 'react-i18next';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import Slider from 'react-slick'

// Settings for the slider
const settings = {
  dots: true,
  arrows: true,
  fade: true,
  infinite: true,
  autoplay: false,
  speed: 1000,
  autoplaySpeed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnFocus: false,
  pauseOnHover: false,
}

export default function Service360() {
  const { t,  i18n } = useTranslation();
  const top = useBreakpointValue({ base: '90%', md: '40%' })
  const side = useBreakpointValue({ base: '30%', md: '40px' })
  const [slider, setSlider] = React.useState(null)
  const textColor = useColorModeValue('blue.500', 'blue.500');
  const bgColor = useColorModeValue('gray.200', 'gray.700');

  const cards = [
    {
      title: t("service360.comprehensiveCare"),
      text: t("service360.comprehensiveText"),
      image:
        '/images/comprehensivecare.jpg',   
    },
    {
      title: t("service360.exclusiveRecoveryHouse"),
      text: t("service360.exclusiveRecoveryHouseText"),
      image:
        '/images/recuperationhouse.jpg',
    },
    {
      title: t("service360.specializedChef"),
      text: t("service360.specializedChefText"),
      image:
        '/images/prochef.jpg', 
    },
    {
      title: t("service360.transportation"),
      text: t("service360.transportationText"),
      image:
        '/images/transportation.jpg',
    },
    {
      title: t("service360.professionalInterpreter"),
      text: t("service360.professionalInterpreterText"),
      image:
        '/images/medicalinterpretation.jpg',
    },
    {
      title: t("service360.medicalTranslation"),
      text: t("service360.medicalTranslationText"),
      image:
        '/images/medicaltranslation.jpg',
    }
  ]

  const sliderRef = React.useCallback((node) => {
    if (node !== null) {
      setSlider(node);
    }
  }, []);
    return (
      <Box w={"85%"}>
        <Header></Header>
        <Box display="flex" flexDirection="column" mt={'20'}>
          <Box p={'3'}>
            <Box
              display="flex"  
              alignItems="space-between"
              justifyContent="space-between"
              flexDirection="column"
            >
              <Stack
                spacing={2}
                justifyContent="space-between"
                alignItems="space-between"
              >
                <Heading color={'blue.500'} textAlign={'center'} >{t("service360.tittle")}</Heading>
              </Stack>
              <Stack
                spacing={10}
                justifyContent="space-between"
                alignItems="space-between"
              >
                <Text p={2} textAlign={'center'} mt={8}>
                {t("service360.description")}
                </Text>
              </Stack>
            </Box>
          </Box>
          <Box position={'relative'} height={'100%'} width={'full'} overflow={'hidden'} mt={20}>

                    <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                    />
                    <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                    />
                    <IconButton
                        bg={'blue.500'}
                        color={'white'}
                      _hover={{
                        transform: 'translateY(-2px)',
                        boxShadow: 'lg',
                        color: textColor,
                        bg: bgColor}}
                        aria-label="left-arrow"
                        variant="ghost"
                        position="absolute"
                        left={side}
                        top={top}
                        transform={'translate(0%, -50%)'}
                        zIndex={2}
                        onClick={() => slider?.slickPrev()}>
                        <BiLeftArrowAlt size="50px" />
                      </IconButton>
                      {/* Right Icon */}
                      <IconButton
                        bg={'blue.500'}
                        color={'white'}
                      _hover={{
                        transform: 'translateY(-2px)',
                        boxShadow: 'lg',
                        color: textColor,
                        bg: bgColor}}
                        aria-label="right-arrow"
                        variant="ghost"
                        position="absolute"
                        right={side}
                        top={top}
                        transform={'translate(0%, -50%)'}
                        zIndex={2}
                        onClick={() => slider?.slickNext()}>
                        <BiRightArrowAlt size="50px" />
                      </IconButton>
                    <Slider {...settings} ref={sliderRef}>
                    {cards.map((card, index) => (
                        <Box
                        key={index}
                        height={'550px'}
                        backgroundPosition="center"
                        backgroundRepeat="no-repeat"
                        backgroundSize="cover"
                        backgroundImage={`url(${card.image})`}>

                        <Box height="100%" position="relative">
                            <Stack
                            spacing={6}
                            w={'100%'}
                            maxW={'lg'}
                            position="absolute"
                            bg="rgba(225, 225, 225, 0.8)"   // Opacidad del fondo 
                            borderRadius={'md'}
                            opacity={0.95}
                            top="70%"
                            left="2%"
                            boxShadow={"3px 3px 3px 3px rgba(128, 128, 128, 0.5)"}
                            p={5}
                            transform="translate(0, -50%)">
                            <Heading  fontSize={"xl"} color="black" align={'center'} >
                                {card.title}
                            </Heading>
                            <Text fontSize={{ base: 'md', lg: 'lg' }} color="black" align={'center'}>
                                {card.text}
                            </Text>
                            </Stack>
                        </Box>
                        </Box>
                    ))}
                    </Slider>
                    </Box>
        </Box>
        <Footer></Footer> 
    </Box>
    )
}