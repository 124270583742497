import {React, useState} from 'react'
import { Flex, Box, Heading } from '@chakra-ui/react';
import { VerifiedDoctorCard } from "./VerifiedDoctorCard.js"
import { FirestoreProvider, useFirestoreContext } from '../../FirestoreContext'


export default function Sidebar(){
    const { getVerifiedDoctors } = useFirestoreContext();
    const [ loaded, setLoaded ] = useState(0);
    const [ doctors, setDoctors ] = useState([]);

    if (!loaded) {
        getVerifiedDoctors().then(querySnapshot => {
            const docs = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setDoctors(docs);
            setLoaded(1);

          }
        );
    
      }
    
    return (
        <Box    p={4}
                borderWidth="0px"
                borderRadius="md"
                width={'100%'}>
                
            <Heading fontSize={22} color={"blue.500"} alignContent={"center"}> Especialistas Verificados </Heading>
             {loaded ? doctors.map((val, index) => <VerifiedDoctorCard doctorData={val} key={index} />) : <></>}

        </Box>
    )

}