import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box, Heading, Text, Stack, Link, useBreakpointValue, IconButton, useColorModeValue } from '@chakra-ui/react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { useTranslation } from 'react-i18next';
import  Header from "../components/Header.js"
import Footer  from "../components/Footer.js"
import Slider from 'react-slick'

// Settings for the slider
const settings = {
    dots: true,
    arrows: true,
    fade: true,
    infinite: true,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnFocus: false,
    pauseOnHover: false,
  }

export default function About () {
    const [slider, setSlider] = React.useState(null)
    const { t,  i18n } = useTranslation();
    const top = useBreakpointValue({ base: '90%', md: '40%' })
    const side = useBreakpointValue({ base: '30%', md: '40px' })
    const textColor = useColorModeValue('blue.500', 'blue.500');
    const bgColor = useColorModeValue('gray.200', 'gray.700');

    const cards = [
        {
          title: t("about.billingualMedicalDirectory"),
          text: t("about.billingualMedicalDirectoryText"),
          image:
            '/images/billingualMedicalDirectory.jpg',
          atrribute:'Imagen de Freepik',    
        },
        {
          title: t("about.forum"),
          text: t("about.forumText"),
          image:
            '/images/medicalForum.jpg',
          atrribute:'Imagen de stefamerpik en Freepik',  
        },
        {
          title: t("about.360Service"),
          text: t("about.360ServiceText"),
          image:
            '/images/service360.jpg',
          atrribute:'Imagen de 8photo en Freepik',    
        },
        {
          title: t("about.brocaMedical"),
          text: t("about.brocaMedicalText"),
          image:
            '/images/medicalTranslation.jpg',
          link:'https://brocamedical.com',  
        }
      ]

      const sliderRef = React.useCallback((node) => {
        if (node !== null) {
          setSlider(node);
        }
      }, []);
      useEffect(() => {
        console.log("Slider Reference:", slider);
    }, [slider]);
    return (

        <Box w={"85%"} textAlign={'center'}>
            <Header></Header>
                <Box mt={20}>
                    <Heading color={'blue.500'}>{t("about.tittle")}</Heading>
                </Box>
                <Box>
                    <Text mt={'8'}>{t("about.description")}</Text>
                </Box>
                <Box mt={20}>
                    <Heading color={'blue.500'} fontSize={28}>{t("about.ourServices")}</Heading>
                </Box>
                <Box position={'relative'} height={'100%'} width={'full'} overflow={'hidden'} mt={20}>

                    <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                    />
                    <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                    />
                    <IconButton
                        bg={'blue.500'}
                        color={'white'}
                      _hover={{
                        transform: 'translateY(-2px)',
                        boxShadow: 'lg',
                        color: textColor,
                        bg: bgColor}}
                        aria-label="left-arrow"
                        variant="ghost"
                        position="absolute"
                        left={side}
                        top={top}
                        transform={'translate(0%, -50%)'}
                        zIndex={2}
                        onClick={() => slider?.slickPrev()}>
                        <BiLeftArrowAlt size="50px" />
                      </IconButton>
                      {/* Right Icon */}
                      <IconButton
                        bg={'blue.500'}
                        color={'white'}
                      _hover={{
                        transform: 'translateY(-2px)',
                        boxShadow: 'lg',
                        color: textColor,
                        bg: bgColor}}
                        aria-label="right-arrow"
                        variant="ghost"
                        position="absolute"
                        right={side}
                        top={top}
                        transform={'translate(0%, -50%)'}
                        zIndex={2}
                        onClick={() => slider?.slickNext()}>
                        <BiRightArrowAlt size="50px" />
                      </IconButton>
                    <Slider {...settings} ref={sliderRef}>
                    {cards.map((card, index) => (
                        <Box
                        key={index}
                        height={'550px'}
                        backgroundPosition="center"
                        backgroundRepeat="no-repeat"
                        backgroundSize="cover"
                        backgroundImage={`url(${card.image})`}>

                        <Box height="100%" position="relative">
                            <Stack
                            spacing={6}
                            w={'100%'}
                            maxW={'lg'}
                            position="absolute"
                            bg="rgba(225, 225, 225, 0.8)"   // Opacidad del fondo 
                            borderRadius={'md'}
                            opacity={0.95}
                            top="70%"
                            left="2%"
                            boxShadow={"3px 3px 3px 3px rgba(128, 128, 128, 0.5)"}
                            p={5}
                            transform="translate(0, -50%)">
                            <Heading  fontSize={"xl"} color="black" align={'center'} >
                                {card.title}
                            </Heading>
                            <Text fontSize={{ base: 'md', lg: 'lg' }} color="black" align={'center'}>
                                {card.text}
                            </Text>
                            <Link fontSize={{ base: 'md', lg: 'lg' }} href={card.link} color="black" align={'center'}>
                                {card.link}
                            </Link>
                            </Stack>
                        </Box>
                        </Box>
                    ))}
                    </Slider>
                    </Box>
                    <Box mt={20}>
                    <Heading color={'blue.500'}>{t("about.mission")}</Heading>
                    </Box>
                    <Box>
                        <Text mt={'8'}>{t("about.missionText")}</Text>
                    </Box>
                    
      <Footer></Footer> 
    </Box>
    )
}