import { Box, Icon, Heading, Text, VStack, Avatar, Spacer, HStack, StackDivider} from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { MdDiamond, MdCheckCircleOutline } from 'react-icons/md'

export function ListArticles({ posts }) {

  const extractText = (html) => {
    const strippedText = html.replace(/<[^>]+>/g, '');
    return strippedText;
  };

  return (
    <>
    {posts.map((pregunta, index) => (
      <Link to={`/article/${pregunta.id}`} key={index}>
      <Box 
          p={4}
          borderWidth="2px"
          borderRadius="md"
          mb={6}>
        <HStack spacing={4} divider={<StackDivider />}>
          <VStack>
                <Avatar src={pregunta.author.image} alt={pregunta.author.name} />
                <HStack>
                      {pregunta.author.isPremium ? <Icon as={MdDiamond} w={6} h={6} color='blue.300' /> : <></> }
                      {pregunta.author.isVerified ?<Icon as={MdCheckCircleOutline} w={6} h={6} color='yellow.500' />: <></> }
                </HStack>

          </VStack>
          <VStack align="start" spacing={4}>
            <Heading size="md" color="blue.500">{pregunta.title}</Heading>

            <Text size="xs">{extractText(pregunta.content).slice(0,60)}</Text>
            <Spacer />
            <Text fontSize="xs" color="gray.500">{new Date(pregunta.date.seconds * 1000).toLocaleDateString()}</Text>
          </VStack>
        </HStack>
      </Box>
      </Link>
    ))}
    </>
  );
}
