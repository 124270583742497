import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  useColorMode,
  Stack,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';


export default function Nav() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  const { t,  i18n } = useTranslation();

  function changeLang(lang) {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
}

  return (
        

      <Flex
            bg={[useColorModeValue('gray.200', 'gray.700'), null, null, 'transparent']}
            position={['fixed', 'static', 'static', 'static']}  // Cambia a posición fija en dispositivos móviles
            bottom={['0', 'auto', 'auto', 'auto']}  // Establece la posición en la parte inferior solo en dispositivos móviles
            zIndex={100}
            justifyContent='space-between'
            alignItems="flex-start"
            width={['98%', 'auto', 'auto', 'auto']}
            // Otras propiedades que desees agregar
          >

          <IconButton
            size={'lg'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />

          <Flex display={{ base: "none", md: 'flex' }} alignItems="flex-start" flexDirection="row" justifyContent="space-between">
          <Link  href="/forum" mr={4}  py={1}  rounded={'md'} _hover={{textDecoration: 'none', color: useColorModeValue('blue.500', 'blue.500'), bg: useColorModeValue('gray.200', 'gray.700')}}> {t("navbar.forum")}</Link>
          <Link  href="/directory" mr={4}  py={1}  rounded={'md'} _hover={{textDecoration: 'none', color: useColorModeValue('blue.500', 'blue.500'), bg: useColorModeValue('gray.200', 'gray.700')}}> {t("navbar.directory")}</Link>
          <Link  href="/articles" mr={4}  py={1}  rounded={'md'} _hover={{textDecoration: 'none', color: useColorModeValue('blue.500', 'blue.500'), bg: useColorModeValue('gray.200', 'gray.700')}}> {t("navbar.articles")}</Link>
          <Link  href="/about" mr={4}  py={1}  rounded={'md'} _hover={{textDecoration: 'none', color: useColorModeValue('blue.500', 'blue.500'), bg: useColorModeValue('gray.200', 'gray.700')}}> {t("navbar.about")}</Link>
          <Link  href="/360service" mr={4}  py={1}  rounded={'md'} _hover={{textDecoration: 'none', color: useColorModeValue('blue.500', 'blue.500'), bg: useColorModeValue('gray.200', 'gray.700')}}> {t("navbar.360service")}</Link>


          </Flex>

          {isOpen ? (
            <Box pb={4} display={{ md: 'none' }}>
            <Stack spacing={4}>
            <Link href="/forum"  mr={4}  py={1}  rounded={'md'} _hover={{textDecoration: 'none'}}> {t("navbar.forum")}</Link>
            <Link  href="/directory" mr={4}  py={1}  rounded={'md'} _hover={{textDecoration: 'none'}}> {t("navbar.directory")}</Link>
            <Link href="/articles"  mr={4}  py={1}  rounded={'md'} _hover={{textDecoration: 'none'}}> {t("navbar.articles")}</Link>
            <Link  href="/about" mr={4}  py={1}  rounded={'md'} _hover={{textDecoration: 'none' }}> {t("navbar.about")}</Link>
            <Link  href="/360service" mr={4}  py={1}  rounded={'md'} _hover={{textDecoration: 'none' }}> {t("navbar.360service")}</Link>
            </Stack>
            </Box>
            ) : null}

            <Box>
            <Button onClick={toggleColorMode}>
                {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
              </Button>
            <Button onClick={ () => changeLang('en') }> EN </Button>
            <Button onClick={ () => changeLang('es') }> ES</Button>
            </Box>
            
        
          </Flex>

        

  );
}