import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box, Heading, Text, Stack, Link, Accordion, AccordionButton, AccordionItem, AccordionIcon, AccordionPanel} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import  Header from "../components/Header.js"
import Footer  from "../components/Footer.js"
import Slider from 'react-slick'



export default function Faq () {
    const { t,  i18n } = useTranslation();


    return (

        <Stack w={['100%', null, null, '80%']} direction={['column', null, null, 'column']} textAlign={'center'}>
            <Header></Header>
            <Heading color={'blue.500'} mt={20}>{t("faq.faqTittle")}</Heading>

            <Accordion  mt={20}>

  <AccordionItem mt={10}>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>
        <Heading color={'blue.500'} fontSize={20}>{t("faq.whoCanRegister")}</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    <Text>{t("faq.whoCanRegisterText")}</Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem mt={10}>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>
        <Heading color={'blue.500'} fontSize={20}>{t("faq.verifyInformation")}</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    <Text mt={2}>{t("faq.verifyInformationText")}</Text>
     
    </AccordionPanel>
  </AccordionItem>


  <AccordionItem mt={10}>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>
        <Heading color={'blue.500'} fontSize={20}>{t("faq.cancelMembership")}</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    <Text mt={2}>{t("faq.cancelMembershipText")}</Text>
     
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem mt={10}>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>
        <Heading color={'blue.500'} fontSize={20}>{t("faq.internationalPatient")}</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    <Text mt={2}>{t("faq.internationalPatientText")}</Text>
     
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem mt={10}>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>
        <Heading color={'blue.500'} fontSize={20}>{t("faq.otherServices")}</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    <Text mt={2}>{t("faq.otherServicesText")}</Text>
     
    </AccordionPanel>
  </AccordionItem>


  <AccordionItem mt={10}>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>
        <Heading color={'blue.500'} fontSize={20}>{t("faq.brocaHealthHighlight")}</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    <Text mt={2}>{t("faq.brocaHealthHighlightText")}</Text>
     
    </AccordionPanel>
  </AccordionItem>


  <AccordionItem mt={10} mb={10}>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>
        <Heading color={'blue.500'} fontSize={20}>{t("faq.paymentPlan")}</Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    <Text mt={2}>{t("faq.paymentPlanText")}</Text>
     
    </AccordionPanel>
  </AccordionItem>

</Accordion>


            <Footer></Footer> 
        </Stack>
    
    )
}