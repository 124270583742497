import { Modal,
    Button,
    Box,
    Checkbox,
    ModalBody,
    ModalFooter,
    ModalContent,
    ModalHeader,
    ModalCloseButton, useDisclosure, ModalOverlay, Center, Text } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

export default function ForumInstructions({isOpen, onClose}) {
    const { t, i18n } = useTranslation();
  return (
    <Box>
        <Modal isOpen={isOpen}
              onClose={onClose}
              
              motionPreset='flip'>
                              <ModalOverlay
                              backdropFilter='blur(10px) hue-rotate(90deg)'/>
                              <ModalContent>
                                <ModalHeader color={'blue.500'}>{t("forumInstructions.title")}</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                <Text>
                                {t("forumInstructions.text")}
                                </Text>
                                <Text mt={'5'}>
                                {t("forumInstructions.text2")}
                                </Text>
                                <Text mt={'5'}>
                                {t("forumInstructions.text3")}
                                </Text>
                                <Text mt={'5'}>
                                {t("forumInstructions.text4")}
                                </Text>
                                <Text mt={'5'}>
                                {t("forumInstructions.text5")}
                                </Text>
                                <Text mt={'5'}>
                                {t("forumInstructions.text6")}
                                </Text>
                                <Text mt={'5'}>
                                {t("forumInstructions.text7")}
                                </Text>

                                </ModalBody>
                              </ModalContent>
            </Modal>
    </Box>
  )  
}
                    
