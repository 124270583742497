  'use client'
  import React, { useState, useEffect } from 'react';
  import {
    Box,
    VStack,
    Button,
    Flex,
    Divider,
    chakra,
    Grid,
    GridItem,
    Container,
    useColorModeValue,
    useBreakpointValue,
  } from '@chakra-ui/react';
  import { useTranslation } from 'react-i18next';
  import {
    IconButton,
    Stack,
    Heading,
    Text,
  } from '@chakra-ui/react'
  import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
  import Slider from 'react-slick'
  import { Link } from 'react-router-dom';

  // Settings for the slider
  const settings = {
    dots: true,
    arrows: true,
    fade: true,
    infinite: true,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnFocus: false,
    pauseOnHover: false,

  }


  export default function TopBanner() {
    const [slider, setSlider] = React.useState(null)
    const { t,  i18n } = useTranslation();
    const top = useBreakpointValue({ base: '90%', md: '40%' })
    const side = useBreakpointValue({ base: '30%', md: '40px' })
    const textColor = useColorModeValue('blue.500', 'blue.500');
    const bgColor = useColorModeValue('gray.200', 'gray.700');

    //const bg = useColorModeValue("gray.50", "gray.800")

    const cards = [
      {
        title: t("topBanner.accesToExpertDoctors"),
        text: t("topBanner.accesToExpertDoctorsText"),
        image:
          'https://img.freepik.com/foto-gratis/doctor-estetoscopio-cerca_23-2149191355.jpg?w=900&t=st=1700692443~exp=1700693043~hmac=641dd69971a3de1d2fb693d6af0c36f6057d0f9185a48af2a20c3625d9f09695',
        atrribute:'Imagen de Freepik',    
      },
      {
        title: t("topBanner.communityHealthEngagement"),
        text: t("topBanner.communityHealthEngagementText"),
        image:
          'https://img.freepik.com/foto-gratis/retrato-sonriente-joven-medicos-posicion-juntos-retrato-personal-medico-dentro-moderno-hospital-sonriente-camara_657921-885.jpg?w=900&t=st=1700692430~exp=1700693030~hmac=f1b49fcb33b8b1a5de782b40f94a452cd5a86ecfe1424fc25bf4a9dca4d6405e',
        atrribute:'Imagen de stefamerpik en Freepik',  
      },
      {
        title: t("topBanner.accesToHealthResources"),
        text: t("topBanner.accesToHealthResourcesText"),
        image:
          'https://img.freepik.com/foto-gratis/doctor-cruzando-brazos-mientras-sostiene-estetoscopio-bata-blanca_176474-8491.jpg?w=900&t=st=1700692418~exp=1700693018~hmac=4ef4474e6633fe545c7c17d02ed1245d89f815e3dbca5014494852968cff1ab9',
        atrribute:'Imagen de 8photo en Freepik',    
      },
      {
        title: t("topBanner.onlineMedicalAppointment"),
        text: t("topBanner.onlineMedicalAppointmentText"),
        image:
          'https://img.freepik.com/foto-gratis/manos-doctora-irreconocible-escribiendo-forma-escribiendo-teclado-portatil_1098-20374.jpg?w=900&t=st=1700692413~exp=1700693013~hmac=9fa07c089cc6e1147d7af4e3921031443c9ccb7d159ce95d59a853820dbd43b7',
        atrribute:'Imagen de pressfoto en Freepik',  
      }
    ]
    const sliderRef = React.useCallback((node) => {
      if (node !== null) {
        setSlider(node);
      }
    }, []);

return (
  <Box w="100%" p={6} m={4} borderRadius="1px">
        <Grid
          p={0}
          templateColumns={{
            base: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(2, 1fr)',
          }}
          gap={2}
          >
          <GridItem colSpan={1} >
            <VStack alignItems="flex-start" spacing="20px">
              <chakra.h2 fontSize="3xl" fontWeight="700" color={"blue.500"}>
              {t("topBanner.joinNow")}
              </chakra.h2>
        <Link to="/register">
        <Button color={'white'} bg="blue.500"  _hover={{textDecoration: 'none', color: useColorModeValue('blue.500', 'blue.500'), bg: useColorModeValue('gray.200', 'gray.700')}}>
        {t("topBanner.registerButton")}
        </Button>
        </Link>
            </VStack>
          </GridItem>
          <GridItem>
            <Flex align={"center"} justifyContent="center" h="100%">
              <chakra.h2 fontSize="xl">
              {t("topBanner.briefDescription")}
              </chakra.h2>
            </Flex>
          </GridItem>
        </Grid>


        <Divider mt={6} mb={6} />


        <Box position={'relative'} height={'100%'} width={'full'} overflow={'hidden'}>

        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />

        <IconButton
          bg={'blue.500'}
          color={'white'}
        _hover={{
          transform: 'translateY(-2px)',
          boxShadow: 'lg',
          color: textColor,
          bg: bgColor}}
          aria-label="left-arrow"
          variant="ghost"
          position="absolute"
          left={side}
          top={top}
          transform={'translate(0%, -50%)'}
          zIndex={2}
          onClick={() => slider?.slickPrev()}>
          <BiLeftArrowAlt size="50px" />
        </IconButton>
        {/* Right Icon */}
        <IconButton
          bg={'blue.500'}
          color={'white'}
        _hover={{
          transform: 'translateY(-2px)',
          boxShadow: 'lg',
          color: textColor,
          bg: bgColor}}
          aria-label="right-arrow"
          variant="ghost"
          position="absolute"
          right={side}
          top={top}
          transform={'translate(0%, -50%)'}
          zIndex={2}
          onClick={() => slider?.slickNext()}>
          <BiRightArrowAlt size="50px" />
        </IconButton>
        <Slider {...settings} ref={sliderRef}>
          {cards.map((card, index) => (
            <Box
              key={index}
              height={'550px'}
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              backgroundImage={`url(${card.image})`}>

              <Box height="100%" position="relative">
                <Stack
                  spacing={6}
                  w={'100%'}
                  maxW={'lg'}
                  position="absolute"
                  bg="rgba(225, 225, 225, 0.8)"   // Opacidad del fondo 
                  borderRadius={'md'}
                  opacity={0.95}
                  top="70%"
                  left="2%"
                  boxShadow={"3px 3px 3px 3px rgba(128, 128, 128, 0.5)"}
                  p={5}
                  transform="translate(0, -50%)">
                  <Heading  fontSize={"xl"} color="black" align={'center'} >
                    {card.title}
                  </Heading>
                  <Text fontSize={{ base: 'md', lg: 'lg' }} color="black" align={'center'}>
                    {card.text}
                  </Text>
                </Stack>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
</Box>
    )
  }