import React from 'react'
import  Header from "../components/Header.js"
import TopBanner  from "../components/TopBanner.js"
import TestimonialCard  from "../components/Testimonials.js"
import Footer  from "../components/Footer.js"
import { useTranslation } from 'react-i18next'
import { VStack, Box, HStack, Flex, Stack } from "@chakra-ui/react"
import { Dashboard } from "../components/dashboard/Dashboard.jsx"

export function DashboardPage () {
  const { t,  i18n } = useTranslation();
  return (
    <Stack w={['100%', null, null, '80%']} direction={['column', null, null, 'column']}
    px={2}>
    <Header></Header>
    
    
      <Flex>
      <Dashboard></Dashboard>
        
      </Flex>
      
    <Footer></Footer>
    </Stack>
  )
}
