import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import "../styles/Carousel.css"
import { Text, Center } from '@chakra-ui/react';

export const Carousel = ({images, isLoggedIn}) => {

  let data = []

  if (isLoggedIn) {
    images.map((img) => data.push({original: img}))
  } else {
    data = [{original : "https://cdn.icon-icons.com/icons2/1189/PNG/512/1490793864-user-interface16_82335.png"}]
  }

  return (
    <div>
      { !isLoggedIn ? <Center><Text>Inicia sesion para ver las imagenes</Text></Center> : <Text></Text> }
      <ImageGallery items={data}  />
    </div>
  );
};


