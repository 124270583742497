import React, { useState, useEffect } from 'react';
import {
  Heading,
  Avatar,
  Box,
  Center,
  Image,
  Flex,
  Text,
  Stack,
  Button,
  useColorModeValue,
  Textarea,
  Container,
  Input,
} from '@chakra-ui/react';
import { useAuthContext } from "../AuthContext";
import { useTranslation } from 'react-i18next';
import { useFirestoreContext } from '../FirestoreContext';

export default function EditProfile() {
  const { t, i18n } = useTranslation();
  const { fileUpload } = useFirestoreContext();
  const { userData } = useAuthContext();
  const [newDisplayName, setNewDisplayName] = useState(userData.displayName || '');
  const [newPhotoURL, setNewPhotoURL] = useState(userData.photoURL || '');
  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  function changeLang(lang) {
    i18n.changeLanguage(lang);
  }

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const path = `profile_images/${userData.uid}/${file.name}`;

      try {
        const downloadURL = await fileUpload(file, path);
        setNewPhotoURL(downloadURL);
        setSelectedImage(file);
        setIsUploadingImage(false);
      } catch (error) {
        console.error('Error uploading image:', error);
        setIsUploadingImage(false);
      }
    }
  };

  const { updateUserInfo } = useFirestoreContext();
  const handleSaveChanges = async () => {
    try {
      console.log('Updating profile with uid:', userData);
      console.log('New displayName:', newDisplayName);
      console.log('New photoURL:', newPhotoURL);

      if (!userData || !userData.uid) {
        console.error('userData or UID is undefined');
        return;
      }

      const usertype = userData.userType; // Assuming you have a 'usertype' field in your user data

      if (selectedImage) {
        // Upload the image to Firebase Storage and get the download URL
        const path = `profile_images/${userData.uid}/${selectedImage.name}`;
        const downloadURL = await fileUpload(selectedImage, path);

        console.log('Uploading image. New downloadURL:', downloadURL);

        // Update user information based on usertype
        await updateUserInfo(userData.uid, { displayName: newDisplayName, photoURL: downloadURL }, usertype);

        // Update the local state if the update is successful
        setNewPhotoURL(downloadURL);
        setSelectedImage(null);
      } else {
        // Update user information based on usertype
        await updateUserInfo(userData.uid, { displayName: newDisplayName, photoURL: newPhotoURL || '' }, usertype); // Provide an empty string as a fallback value
      }

      // Handle success (e.g., show a success message)
      console.log('Profile updated successfully');
    } catch (error) {
      // Handle error (e.g., show an error message)
      console.error('Error updating profile:', error);
    }
  };


  const handleNameChange = (e) => {
    setNewDisplayName(e.target.value);
  };

  // Agrega un efecto de efecto secundario para ver las propiedades de userData en la consola
  useEffect(() => {
    console.log('userData:', userData);
  }, [userData]);

  return (
    <Stack py={3}>
      <Box
        maxW={'600px'}
        w={'600px'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}
        textAlign={'center'}
      >
        <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'} m={6}  >
          {t("profile.edit_profile")}
        </Heading>
        <Flex justify={'center'} mt={5}>
          <label htmlFor="profile-image-upload" style={{ position: 'relative', cursor: 'pointer' }}>
            <div
              style={{
                width: '200px', // Tamaño fijo del contenedor de la imagen
                height: '200px', // Tamaño fijo del contenedor de la imagen
                overflow: 'hidden',
                borderRadius: '50%',
              }}
            >
              <Avatar
                size={'full'}
                src={newPhotoURL || userData.photoURL}
                alt={'Author'}
                css={{
                  border: '2px solid #00aad0',
                  width: '100%', // Ajustar la imagen al tamaño del contenedor
                  height: '100%', // Ajustar la imagen al tamaño del contenedor
                }}
              />
            </div>
            <Input
              id="profile-image-upload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />
          </label>
        </Flex>

        <Box p={6}>
          <Stack spacing={0} align={'left'} mb={5}>
            <Text mb='8px'>{t("profile.name")}</Text>
            <Textarea
              placeholder={userData.displayName}
              size='sm'
              value={newDisplayName} // Bind value to the newDisplayName state
              onChange={handleNameChange} // Add onChange handler to update newDisplayName
            />
          </Stack>

          <Stack spacing={0} align={'left'} mb={5}>
            <Text mb='8px'>{t("profile.email")}</Text>
            <Textarea
              placeholder={userData.email}
              size='sm'
            />
          </Stack>
          <Button
            w={'full'}
            mt={8}
            bg={useColorModeValue('#00aad0', 'gray.900')}
            color={'white'}
            rounded={'md'}
            onClick={handleSaveChanges}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
            }}
          >
            {t("profile.save_changes")}
          </Button>
        </Box>
      </Box>
    </Stack>
  );
}
