import {React, useState} from 'react'
import  Header from "../components/Header.js"
import { FirestoreProvider, useFirestoreContext } from '../FirestoreContext'
import Footer  from "../components/Footer.js"
import RecentQuestions from "../components/forum/RecentQuestions.js"
import  NewQuestion from "../components/forum/NewQuestion.js"
import  { ListArticles } from "../components/articles/ListArticles"
import { useTranslation } from 'react-i18next'
import { useAuthContext } from "../AuthContext";
import Sidebar from "../components/sidebar/Sidebar.js"
import { Flex, Box, HStack, chakra, Stack } from "@chakra-ui/react"

export function Articles () {
  const { t,  i18n } = useTranslation();
  const { lastArticles } = useFirestoreContext();
  const [posts, setPosts] = useState([]);
  const [ loaded, setLoaded ] = useState(0);
  const [ option, setOption ] = useState("forum");
  const { userData, isLoggedIn } = useAuthContext(); 

  if (!loaded) {
    const sisas = lastArticles().then(querySnapshot => {
        const preguntas = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setPosts(preguntas);
        setLoaded(1);
      }
    );

  }

  return (
    <Stack w={['100%', null, null, '80%']} direction={['column', null, null, 'column']}
    px={2}>
    <Header></Header>
    <chakra.h2 fontSize="2xl" fontWeight="700" my={10} color={'blue.500'}>
        {t("articles.recentArticles")}
      </chakra.h2>
    <Stack align="flex-start"
          text-align="center"
           direction={['column', null, null, 'row']}>
     
        <Box w={['100%', null, null, '80%']} m="auto">
          { loaded ? <ListArticles posts={posts} ></ListArticles>: <></> }
        </Box>
        <Box  w={['100%', null, null, '20%']} m="auto">
          <Sidebar ></Sidebar>
        </Box>
    </Stack>
    <Footer></Footer>
    </Stack>
  )
}
