import React, { useState } from 'react';
import Quill from 'react-quill';
import ReactQuill from 'react-quill';
import DOMPurify from 'dompurify';
import 'react-quill/dist/quill.snow.css';
import { Button } from '@chakra-ui/react';


export function RichTextEditor({onChange, defaultV}) {
    const [editorHtml, setEditorHtml] = useState(defaultV);
  
    function print () {
        console.log(editorHtml);
    }

    function sanitizeContent  (html)  {
        setEditorHtml(html);
        onChange(editorHtml);
        return { __html: DOMPurify.sanitize(html) };
      }

    return (

      <div>
        <Quill
          value={editorHtml}
          onChange={sanitizeContent}
          theme="snow"

        />
    </div>
    );
  }
  