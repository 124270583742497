import { useAuthContext } from '../AuthContext';
import { Flex, Img } from '@chakra-ui/react'
import Nav from './Navbar';

import { LoginBar } from './LoginBar';
import { LoginAvatar } from './LoginAvatar';
import { Link } from 'react-router-dom';

export default function Header (props) {
  const { isLoggedIn } = useAuthContext();
  return ( 
    <Flex justifyContent="space-around" flexDirection="column">

    <Flex
      alignItems="flex-start"
      flexDirection={['column', null, null, 'row']}
      justifyContent="space-between"
      mb={10}
    >
      <Link to={'/'}>
          <Img src='/images/logo.png' 
          m="auto"
          width={["80%", "200px", "250px", "300px"]}
          _hover={{
            transform: 'translateY(-1px)',
            boxShadow: 'sm',
          }}>

          </Img>
      </Link>
          <Flex mt={[10, 10, 10, "auto"]}>

            {isLoggedIn ? <LoginAvatar />  : <LoginBar />}

          </Flex>
        
      </Flex>

       <Nav></Nav>
      
       </Flex>
  )
  

}