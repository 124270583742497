import React, { useState, useEffect } from 'react';
import {
  Heading,
  Avatar,
  Box,
  Stack,
  Center,
  Image,
  Flex,
  Text,
  HStack,
  Button,
  useColorModeValue,
  Textarea,
  Container,
  Input,
  Select,
  Checkbox,
  CheckboxGroup
} from '@chakra-ui/react';
import { useAuthContext } from "../../AuthContext";
import { useTranslation } from 'react-i18next';
import { useFirestoreContext } from '../../FirestoreContext';
import { MultiSelect } from 'chakra-multiselect';
import { RichTextEditor } from "../TextEditor";
import { getValue } from '@testing-library/user-event/dist/utils';
export function EditPublicInfo() {
  const { t, i18n } = useTranslation();
  const { getPage, updateDocument, getServices, fileUpload, getLanguages, getPaymentMethods, getDisease  } = useFirestoreContext();
  const { userData } = useAuthContext();
  const [selectedImage, setSelectedImage] = useState({});
  const [pageData, setPageData] = useState({});
  const [updated, setUpdated] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [services, setServices] = useState ([]);
  const [loaded, setLoaded] = useState(false);
  const [disease, setDisease] = useState([]);
  const textColor = useColorModeValue('blue.500', 'blue.500');
  const bgColor = useColorModeValue('gray.200', 'gray.700');
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [payment, setPayment] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [selectedDiseases, setSelectedDiseases] = useState([]);


  useEffect(() => {
    getLanguages().then((res) => setLanguages(res.data()));
    getServices().then((res) => setServices(res)); // Update this line
    getPaymentMethods().then((res) => setPayment(res.data()));
  }, []);
  
  useEffect(() => {
    getDisease().then((res) => {
      console.log('Disease data:', res.data());
  
      // Initialize an empty array to store the result
      const resultList = [];
      // Iterate over each field (enfermedades)
      for (const field in res.data()) {
        // Iterate over each value in the field
        res.data()[field].forEach((value) => {
          // Create a string and push it to the result array
          resultList.push(`${field}-${value}`);
        });
      }
  
      // Log the resulting array
      console.log('Resulting strings:', resultList);
  
      // Create options array for MultiSelect
      const options = resultList.map((item) => ({ label: item, value: item }));
  
      // Set the disease state with the resulting array
      setDisease(options);
  
      
    });
  }, []);
  

  if (!pageData.uid){ //Se cargan los datos de la pagina si no se ha hecho
    getPage(userData.profileURL).then((res)=> {
      setPageData(res);
      // Seteamos los valores por defecto
      //----------------------------------
      setSelectedPayment(res.paymentMethods.map((val) => ({value:val}))); 
      setSelectedLanguages(res.languages.map((val) => ({value:val})));
      setSelectedServices(res.services.map((val) => ({value:val})));
      setSelectedDiseases(res.diseasesYouTreat.map((val) =>({value: val, label:val})))
      console.log('Values existing in db:', userData)
    });
      
  }

const handleImageSelect = async (e) => {
  const file = e.target.files[0];
  console.log(file);
  setSelectedImage(file);
};

// Función para actualizar los campos en newProfileData
const handleFieldChange = (fieldName, value) => {
    var data = pageData;
    data[fieldName] = value;
    setPageData(data);

};

const getDefaultValue = (field) => {
  // Verifica si el campo existe en la base de datos y retorna su valor, de lo contrario, retorna una cadena vacía
  return pageData[field] || '';
};

  const handleSaveChanges = async () => {
    
    if (selectedImage.name){ //si se selecciono una imagen, la carga
        const path = `cover_images/${pageData.uid}/${selectedImage.name}`;

        fileUpload(selectedImage, path).then((res)=> {
          var newData = { ...pageData };
          newData.coverImage = res;
          newData.services = selectedServices.map((val)=> val.value);
          newData.paymentMethods = selectedPayment.map((val)=> val.value);
          newData.languages = selectedLanguages.map((val)=> val.value);
          newData.diseasesYouTreat = selectedDiseases.map((val) => val.value);

          updateDocument(pageData.uid,"profileConfig", newData).then((res) => setUpdated(true));
        })
    } else {   //si  no hay imagen seleccionada omite la carga
      var newData = { ...pageData };

      newData.services = selectedServices.map((val)=> val.value); // Update services field
      newData.languages = selectedLanguages.map((val)=> val.value);
      newData.paymentMethods = selectedPayment.map((val)=> val.value);
      newData.diseasesYouTreat = selectedDiseases.map((val) => val.value);
      console.log(newData);
      updateDocument(pageData.uid,"profileConfig", newData).then((res) => setUpdated(true));
    
  };
} 
 
  return (
      pageData.uid ?
      <Box
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}
        textAlign={'center'}
      >
        <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'} m={6}  >
          {t("profile.editPage")}
        </Heading>

        <Flex justify={'center'} mt={5} w="100%">
          <label htmlFor="cover-image-upload" style={{ position: 'relative', cursor: 'pointer' }} w="100px">
            <div
              style={{
                width: '100%', // Tamaño fijo del contenedor de la imagen
                height: '250px', // Tamaño fijo del contenedor de la imagen
                overflow: 'hidden',
              }}
            >

          <Stack overflow={"hidden"}>
          <Image
              size="full"
              src={selectedImage.name ? "https://na.eventscloud.com/file_uploads/5902ee8dfd28fc01bcb25ce2bf726346_File_Upload_Banner_Final.png" : pageData.coverImage }
              alt="Fondo"
              overflow="hidden"
            />
          <Image
              position="absolute"
              top="10"
              left="10"
              w={"100px"}
              src="https://na.eventscloud.com/file_uploads/5902ee8dfd28fc01bcb25ce2bf726346_File_Upload_Banner_Final.png"
              alt="Superpuesta"
          />
                
          </Stack>

            </div>
            <Input
              id="cover-image-upload"
              type="file"
              accept="image/*"
              onChange={handleImageSelect}
              style={{ display: 'none' }}
            />
          </label>
        </Flex>

        <Box p={6}>

        <Stack spacing={0} align={'left'} mb={5}>
            <Text mb='8px'>{t("profile.header")}</Text>
            <Textarea
             placeholder={pageData.header}
              size='sm'
              defaultValue={getDefaultValue('header')}
              onChange={(e) => handleFieldChange('header', e.target.value)}
            />
          </Stack>

          <Stack spacing={0} align={'left'} mb={5}>
            <Text mb='8px'>{t("profile.bio")}</Text>
            <RichTextEditor
              placeholder={getDefaultValue('bio')}
              defaultV={getDefaultValue('bio')}
              size='sm'
              onChange={(value) => handleFieldChange('bio', value)}
            />
          </Stack>
          <Stack spacing={1} align={'left'} mb={5}>
          <Text mb="8px">{t("profile.diseases")}</Text>
          <MultiSelect
            options={disease}
            value={selectedDiseases}
            onChange={(values) => setSelectedDiseases(values)}
          />
        </Stack>
        <Stack spacing={1} align={'left'} mb={5}>
          <Text mb="8px">{t("profile.servicesDoctor")}</Text>
          <MultiSelect
            options={Object.values(services).map((item) => ({ value: item}))}
            value={selectedServices}
            onChange={setSelectedServices}
          />
        </Stack>
        <Stack spacing={1} align={'left'} mb={5}>
          <Text mb="8px">{t("profile.languages")}</Text>
          <MultiSelect
            options={Object.values(languages).map((item) => ({ value: item}))}
            value={selectedLanguages}
            onChange={setSelectedLanguages}
          />
        </Stack>

        <Stack spacing={1} align={'left'} mb={5}>
          <Text mb="8px">{t("profile.paymentMethod")}</Text>
          <MultiSelect
            options={Object.values(payment).map((item) => ({ value: item}))}
            value={selectedPayment}
            onChange={setSelectedPayment}
          />
        </Stack>

           <Stack spacing={0} align={'left'} mb={5}>
            <Text mb='8px'>{t("profile.phone")}</Text>
            <Input
              placeholder={pageData.phone}
              defaultValue={getDefaultValue('phone')}
              size='sm'
              onChange={(e) => handleFieldChange('phone', e.target.value)}
            />
          </Stack>

          <Stack spacing={0} align={'left'} mb={5}>
            <Text mb='8px'>{t("profile.address")}</Text>
            <Input
              placeholder={pageData.address}
              defaultValue={getDefaultValue('address')}
              size='sm'
              onChange={(e) => handleFieldChange('address', e.target.value)}
            />
          </Stack>

          <Stack spacing={0} align={'left'} mb={5}>
            <Text mb='8px'>{t("profile.webPage")}</Text>
            <Input
              placeholder={pageData.webpage}
              defaultValue={getDefaultValue('webpage')}
              size='sm'
              onChange={(e) => handleFieldChange('webpage', e.target.value)}
            />
          </Stack>

          

          {!updated ?  
          <Button
            w={'full'}
            mt={8}
            rounded={'md'}
            onClick={handleSaveChanges}
            color={'white'} bg="blue.500"  
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
              textDecoration: 'none',
              color: textColor,
              bg: bgColor
            }}>
            {t("profile.save_changes")}
          </Button>
          : <Text>Informacion actualizada</Text>}
        </Box>
      </Box>
    : <></>
  );
}