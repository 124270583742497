import { Box, Icon, Heading, Text, VStack, Avatar, Spacer, Image, HStack, StackDivider, Tag, Stack} from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { MdDiamond, MdTranslate, MdLocationCity, MdWorkspacePremium, MdShield } from 'react-icons/md'

export function ListDoctors({ posts }) {
  const extractText = (html) => {

    // Check if html is a string
    if (typeof html !== 'string') {
      return ''; // or handle it in a way that makes sense for your application
    }
  
    // Use replace only if html is a string
    const strippedText = html.replace(/<[^>]+>/g, '');
    return strippedText;

  };

  return (
    <Stack overflow={"hidden"}>

    {posts.map((pregunta, index) => (
      <Link to={`/doctor/${pregunta.profileURL}`} key={index}>
      <Box 
          p={4}
          borderWidth="2px"
          borderRadius="md"
          mb={6}>
        <Stack 
        direction={['column', null, null, 'row']} 
        spacing={4} divider={<StackDivider />}>
          <VStack >
                  <Box
                    position="relative"
                    overflow="hidden"
                    borderWidth="2px"
                    borderRadius="md"
                    w={200}
                    h={200} 
                    align="center"
                  >
                    <Avatar
                      src={pregunta.photoURL}
                      alt={pregunta.displayName}  
                      overflow="hidden"
                      borderRadius="0.5"
                      w={"200"}
                      h={"200"}
                    />
                    <HStack
                      position="absolute"
                      top={0}
                      right="55%"
                      
                      zIndex={'2'}
                    >
                      
                    </HStack>
                    <Box
                      position="absolute"
                      bottom={0}
                      left="0%"
                      right={'0%'}
                      height={'45px'}
                      zIndex={'1'}
                    >
                      {pregunta.isVerified &&
                      //IMAGEN VERIFICADO
                      <Image src='/images/verified.png'  w={'full'}/>}
                    </Box>
                  </Box>
          </VStack>
          <VStack align="start" spacing={4}>
            <Heading color="blue.500" size="md">{extractText(pregunta.displayName).slice(0,60)} {pregunta.isPremium && <Icon as={MdWorkspacePremium} w={"20px"} h={"20px"} mx={1} color='blue.300' />} </Heading>
            
            <Text size="xs">{pregunta.speciality}</Text>
            
            <Text size="xs"><Icon as={MdLocationCity} w={"20px"} h={"20px"} mx={1} color='blue.300' /> {pregunta.city}</Text>
            <Spacer />
            <Text fontSize="xs" color="gray.500"> {extractText(pregunta.bio).slice(0, 245)} </Text>
            <HStack spacing={4}>
            <Icon as={MdTranslate} w={"20px"} h={"20px"} mx={1} color='blue.300' />
            { pregunta.languages ? pregunta.languages.map((lang, key) =>
            <Tag size={"sm"} key={key} variant='subtle' colorScheme='blue'> {extractText(lang).slice(0, 245)} </Tag> ) : <></>
            }
            </HStack>

            <HStack spacing={4}>
            <Icon as={MdShield} w={"20px"} h={"20px"} mx={1} color='blue.300' />
            { pregunta.paymentMethods ? pregunta.paymentMethods.map((lang, key) =>
            <Tag size={"sm"} key={key} variant='subtle' colorScheme='blue'> {extractText(lang).slice(0, 245)} </Tag> ) : <></>
            }
            </HStack>

          </VStack>
        </Stack> 
      </Box>
      </Link>
    ))}
    </Stack>
  );
}