import { OpinionsOnly } from "./publicProfile/OpinionsOnly";
import { useFirestoreContext } from "../FirestoreContext";
import { useState } from "react";
import { Spinner, Box} from "@chakra-ui/react";

export function TestimonialCard(){
  const { getEvals } = useFirestoreContext();

    const [opinions, setOpinions] = useState(null);

    if (opinions) {
        console.log(Object.keys(opinions).length);
    } else {
        getEvals("drchapatin").then((res) => setOpinions(res));
    }
  return(
    opinions ?
    <Box>
    <OpinionsOnly opinions={opinions} mt={20} ></OpinionsOnly>
    </Box>
    : <Spinner></Spinner>
  )
}
