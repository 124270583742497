import { Menu, MenuButton, Center, Avatar, MenuDivider, MenuList, MenuItem, Button } from "@chakra-ui/react"
import { useAuthContext } from "../AuthContext"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function LoginAvatar(){
  const { userData, logout } = useAuthContext();
  const { t } = useTranslation();
  var data = userData;
  if (!userData) {
    data = {};
  }

return (
                <>
                <Menu px="5">
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}>
                  <Avatar
                    _hover={{
                      transform: 'translateY(-2px)',
                      boxShadow: 'sm',
                    }}
                    borderColor={'papayawhip'}
                    showBorder={true}
                    size={["xl", "md", "md", "xl"]}
                    src={ data.photoURL }
                    referrerPolicy="no-referrer"
                  />
                </MenuButton>
                <MenuList alignItems={'center'} px="5">
                  <br />
                  <Center>
                    <Avatar
                      size={'2xl'}
                      src={ data.photoURL }
                      referrerPolicy="no-referrer"
                    />
                  </Center>
                  <br />
                  <Center>
                    <p>{ data.displayName }</p>
                  </Center>
                  <Center>
                    <p>{ data.email }</p>
                  </Center>
                  <br />
                  <MenuDivider />
                  <Link to={data.userType==="user" ? "/profile" : "/dashboard"}>
                    <MenuItem>{t("avatarmenu.myprofile")}
                      
                    </MenuItem>
                  </Link>
                  <MenuItem onClick={logout}>{t("avatarmenu.logout")}</MenuItem>
                </MenuList>
              </Menu> 

              
              </>
              )}