import React, { createContext, useContext, useState, useEffect} from 'react';
import { db, storage } from './firebase/credentials';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, query, orderBy, limit, getDocs, getDoc, where, doc, updateDoc, onSnapshot } from "firebase/firestore"; 
import {
  ref,
  uploadBytes,
  getDownloadURL
} from "firebase/storage";


export const FirestoreContext = createContext();
export const useFirestoreContext = () => useContext(FirestoreContext);


export function FirestoreProvider ({ children }) {

  const navigate = useNavigate();

  const collectionRef = collection(db, "preguntas");
  const responsesRef = collection(db, "respuestas");


  function lastQuestions(){
    const q = query(collection(db, "preguntas"), limit(10));

    return getDocs(q)

  }

  function lastArticles(){
    const q = query(collection(db, "articulos"), limit(10));

    return getDocs(q)

  }

  function createArticle(questionData) {
    const colRef = collection(db, "articulos");
    return addDoc(colRef, questionData);
      
  }

  function getVerifiedDoctors(){
    const q = query(collection(db, "profileConfig"), limit(10), where('isVerified', '==', true));

    return getDocs(q)

  }

  function getQuestion(q_id){
    const docRef = doc(collectionRef, q_id);
    
    return getDoc(docRef);
  }

  function getArticle(q_id){
    const colRef = collection(db, "articulos");
    const docRef = doc(colRef, q_id);
    
    return getDoc(docRef);
  }
  
  function getResponses(question_id) {
    
      // Crear una consulta que filtre los documentos por el campo "question_id" igual al argumento "question_id"
      const q = query(responsesRef, where("question_id", "==", question_id), orderBy("date", "desc"), limit(10));
      // Obtener los documentos de la colección que coincidan con la consulta
      return getDocs(q)
  }

  function createQuestion(questionData) {

    return addDoc(collectionRef, questionData)
      
  }

  function createResponse(responseData) {

    return addDoc(responsesRef, responseData)
      
  }

  function createArticleResponse(responseData) {
    const colRef = collection(db, "articulos");
    return addDoc(colRef, responseData)
      
  }

  async function fileUpload(file, path) {
    if (!file) {
      return Promise.reject('No file selected');
    }
  
    const storageRef = ref(storage, `/${path}/${file.name}`);
  
    return uploadBytes(storageRef, file)
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref); // Obtain and return the download URL
      });
  }

  async function updateUserInfo(uid, data, userType) {
    let collectionName;
    
    // Determinar la colección según el valor de usertype
    if (userType === "user") {
      collectionName = "usuarios";
    } else if (userType === "doctor") {
      collectionName = "profileConfig";
    } else {
      throw new Error("Valor de usertype no válido");
    }
  
    const userRef = doc(db, collectionName, uid);
  
    try {
      await updateDoc(userRef, data);
      console.log('Información actualizada en Firestore');
    } catch (error) {
      console.error('Error actualizando información de usuario en Firestore:', error);
      throw error;
    }
  }

  async function updateDocument(docName, collName, data) {
    const docRef = doc(db, collName, docName); 
    try {
      await updateDoc(docRef, data); 

      console.log(collName, docName, " updated");
    } catch (error) {
      console.error('Error actualizando documento', error);
      throw error; 
    }
  }

  function getPage(profileURL) {
    return getUid(profileURL).then((uid) => {
      const profileRef = doc(collection(db, "doctores"), uid);
      const pageRef = doc(collection(db, "profileConfig"), uid);
  
      return getDoc(profileRef)
        .then((profile) => {
          const profileData = profile.data();
          return getDoc(pageRef).then((page) => {
            const pageData = page.data();
  
            // Combina las propiedades de ambos objetos en uno solo
            const combinedData = {   //TODO: Eliminar esta cominacion de objetos para usar solo profileConfig
              ...profileData,
              ...pageData,
              
            };
  
            return combinedData;
          });
        });
    });
  }

async function updateProfileConfig(uid, newProfileData) {
  const docRef = doc(collection(db, "profileConfig"), uid);
  
  try {
    await updateDoc(docRef, newProfileData);
    console.log('Campos de profileConfig actualizados con éxito');
  } catch (error) {
    console.error('Error al actualizar campos de profileConfig:', error);
    throw error;
  }
};

function getEvals(profileURL) {
    
  const collectionRef = collection(db, "doctores");
  const q = query(collectionRef, where("profileURL", "==", profileURL), limit(1));

  // Obtener los documentos de la colección que coincidan con la consulta
  return getDocs(q).then((docs) => {
    const docRef = doc(collection(db, "evaluaciones"), docs.docs[0].data().uid);
    return getDoc(docRef).then((document) => document.data());
  })};

async function createEval(profileURL, data) {

    getUid(profileURL).then((uid)=> {
      const docRef = doc(db, "evaluaciones", uid); 
      updateDoc(docRef, data); 
    });
  };

async function createAppointment(profileURL, data) {

    getUid(profileURL).then((uid)=> {
      const docRef = doc(db, "citas", uid); 
      updateDoc(docRef, data); 
    });
  };

function getUid(profileURL) {
    
    const collectionRef = collection(db, "doctores");
    const q = query(collectionRef, where("profileURL", "==", profileURL), limit(1));

    // Obtener los documentos de la colección que coincidan con la consulta
    return getDocs(q).then((docs) => {
      return(docs.docs[0].data().uid);
  })
};

function getSpecialities(){
  return getDoc(doc(collection(db, "lookup"), "especialidades")).then((docu) => {return (docu)})
}
function getLanguages(){
  return getDoc(doc(collection(db, "lookup"), "idiomas")).then((docu) => {return (docu)})
}

function getCities(){
  return getDoc(doc(collection(db, "lookup"), "ciudades")).then((docu) => {return (docu)})
}
function getPaymentMethods(){
  return getDoc(doc(collection(db, "lookup"), "formas_de_pago")).then((docu) => {return (docu)})
}

function getStoredLanguage() {
  return localStorage.getItem('language') || 'es'; 
}

function getServices() {
  const lang = getStoredLanguage();

  return getDoc(doc(collection(db, 'lookup'), 'servicios_' + lang))
    .then((docu) => {
      const servicesData = docu.data(); // Use .data() directly
      // Check if servicesData is not null or undefined before using Object.values
      if (servicesData) {
        // Modificar la función para devolver un array de valores
        return Object.values(servicesData);
      } else {
        return []; // Return an empty array if there is no data
      }
    })
    .catch((error) => {
      console.error('Error fetching services:', error);
      return []; // Return an empty array in case of an error
    });
}

function getDisease(){
  return getDoc(doc(collection(db, "lookup"), "enfermedades")).then((docu) => {return (docu)})
}

function subscribeToDoc(docname, callback){

  return onSnapshot(doc(collection(db, "citas"), docname), (snapshot) => {callback(snapshot.data())} )

};
 
  return (
    <FirestoreContext.Provider value={ { 
    lastQuestions,
     createQuestion, 
     getQuestion, 
     createResponse, 
     getResponses,
     fileUpload, 
     getVerifiedDoctors,
     updateUserInfo,
     updateDocument,
     getPage,
     updateProfileConfig,
     getEvals,
     createEval,
     getUid,
     createAppointment,
     getSpecialities,
     lastArticles,
     createArticle,
     getArticle,
     createArticleResponse,
     subscribeToDoc,
     getServices,
     getLanguages,
     getPaymentMethods,
     getCities,
     getDisease,
     }}>
      { children }
    </FirestoreContext.Provider>
  );
}