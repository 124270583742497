import { useState } from 'react';
import { Flex, IconButton, Icon } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';

export function Stars(props) {
  const [rating, setRating] = useState(props.Rating);
  const handleStarClick = (starIndex) => {
    // Cuando se hace clic en una estrella, actualiza el estado de calificación.
    if (!props.Rating){
    setRating(starIndex + 1);
    };

    if (props.Callback){
    props.Callback(starIndex);
    }
  };

  const totalStars = 5;
  const stars = [];

  for (let starIndex = 0; starIndex < totalStars; starIndex++) {
    stars.push(
      <IconButton
      variant={"ghost"}
        isRound={true}
        key={starIndex}
        aria-label={`Calificar con ${starIndex + 1} estrellas`}
        icon={<Icon as={StarIcon} w={4} h={4} />}
        color={starIndex < rating ? 'yellow.400' : 'gray.300'}
        onClick={() => handleStarClick(starIndex)}
      />
    );
  }

  return <Flex align="center">{stars}</Flex>;
}
