'use client'

import { Avatar, Box, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';
export function Thanks() {
  const { t } = useTranslation();
  return (
    <Stack
      py={16}
      px={8}
      spacing={{ base: 8, md: 10 }}
      align={'center'}
      direction={'column'}>
      <Text fontSize={{ base: 'xl', md: '2xl' }} textAlign={'center'} maxW={'3xl'}>
      {t("profileContent.thanksMessage")}
      </Text>
      <Box textAlign={'center'}>
        <Avatar
          src='https://cdn-icons-png.flaticon.com/512/6819/6819083.png' 
          mb={2}
        />

        <Text fontWeight={600}>{t("profileContent.thanksMessage2")}</Text>
      </Box>
    </Stack>
  )
}