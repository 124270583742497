import {
  Box,
  Center,
  Heading,
  Image,
  Text,
  Stack,
  Avatar,
  useColorModeValue,
  StackDivider
} from '@chakra-ui/react';

export default function HorizontalCard({props}) {

  const extractText = (html) => {
    const strippedText = html.replace(/<[^>]+>/g, '');
    return strippedText;
  };

    return (
      <Center as='a' href={"/question/" + props.id}>
        <Stack 
          mt={2} 
          pb={3}
          direction={'column'} 
          spacing={4} 
          align={'center'}
          justifyContent="space-between"
          w={'full'}
          maxH={"400px"}
          bg={useColorModeValue('white', 'gray.900')}
          boxShadow={'2xl'}
          rounded={'md'}
          overflow={'hidden'}
          minHeight={"sm"}
          
          >

        <Box bg={'gray.100'} pos={'relative'} h={"200px"}  overflow={"hidden"}>
              <Image mt="5" objectFit="cover" src={props.images[0]} h={"200px"} w={"100%"} objectPosition="top left" overflow="hidden"/>
        </Box>
          <Stack mt={2} p={3}>
            <Heading
              color={useColorModeValue('blue.500', 'white')}
              fontSize={'sm'}
              mt={2}
              fontFamily={'body'}>
              {props.title}
            </Heading>
            <Text color={'gray.500'} fontWeight={100}
              fontSize={'xs'}>
              {extractText(props.content).slice(0,100)}
            </Text>
          </Stack>
          <Stack mt={2} direction={'row'} spacing={4} >
            <Avatar
              src={props.author.image}
              alt={'Author'}
            />
            <Stack direction={'column'} spacing={0} fontSize={'2xs'} alignItems={"center"}>
              <Text fontWeight={600}> { props.author.name } </Text>
              <Text color={'gray.500'}>{props.fecha}</Text>
            </Stack>
            </Stack>
          </Stack>
      </Center>
    );
  }

HorizontalCard.defaultProps = {
    title: 'Hola, mundo!',
    author: {image: "https://fugitives.com/wp-content/uploads/2022/12/Avatar-Ending-Explained-2009-Fantasy-Thriller-Film-696x391.jpg"},
    fecha: "Feb 3"
  };