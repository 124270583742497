import {React, useState} from 'react'
import  Header from "../components/Header.js"
import { useParams } from 'react-router-dom';
import TopBanner  from "../components/TopBanner.js"
import TestimonialCard  from "../components/Testimonials.js"
import Footer  from "../components/Footer.js"
import { useTranslation } from 'react-i18next'
import { VStack, Box, HStack, Center, Spinner } from "@chakra-ui/react"
import { Content } from "../components/publicProfile/Content"
import { useFirestoreContext } from "../FirestoreContext"


export function DoctorPublicProfile () {

  const { getPage } = useFirestoreContext();
  const { t,  i18n } = useTranslation();
  const { profileURL } = useParams();
  const [ pageData, setPageData ] = useState({});

  if (!pageData.uid){
    getPage(profileURL).then((res) => setPageData(res));
  }

  return (
    <Box w={"80%"}>
    <Header></Header>
    {pageData ?
    <HStack align="flex-start" w={"100%"}> 
      <VStack w={"100%"}>
        <Content pageData={pageData}/>
      </VStack>
    </HStack>

    : <Center> <Spinner size='xl' /> </Center>}
    <Footer></Footer>
    </Box>
  )
}