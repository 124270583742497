import React, { useState } from 'react';
import { useAuthContext } from "../../AuthContext";
import {
    Heading,
    Avatar,
    Box,
    Center,
    Image,
    Flex,
    Text,
    Stack,
    Button,
    useColorModeValue,
    Textarea,
    Container,
    Input,
    Alert,
    AlertIcon,
    InputRightElement,
    InputGroup,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export function ChangePassword() {
    const { reauthenticateUser, changePassword } = useAuthContext();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [currentPasswordError, setCurrentPasswordError] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const { t, i18n } = useTranslation();

    function changeLang(lang) {
        i18n.changeLanguage(lang);
    }

    const handleChangePassword = async () => {
        setError('');
        setCurrentPasswordError('');

        if (newPassword === '' || confirmPassword === '') {
            setError('Por favor, ingresa una nueva contraseña y confírmala.');
            return;
        }

        if (newPassword !== confirmPassword) {
            setError('Las contraseñas no coinciden.');
            return;
        }

        try {
            // Reautenticar al usuario antes de cambiar la contraseña
            await reauthenticateUser(currentPassword);

            // Cambiar la contraseña
            await changePassword(newPassword);

            // Limpiar campos
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');

            // Mostrar mensaje de éxito
            setError('Contraseña cambiada con éxito.');
        } catch (error) {
            setCurrentPasswordError('Contraseña actual incorrecta.');
            console.error(error);
        }
    };

    return (
        <Stack py={3} w={"100%"}>
            <Box
                bg={useColorModeValue('white', 'gray.800')}
                boxShadow={'2xl'}
                rounded={'md'}
                overflow={'hidden'}
                textAlign={'center'}
            >
                <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'} m={6} >
                    {t("profile.changePassword")}
                </Heading>
                <Box p={1}>
                    <Stack>
                        <Text mb='8px'>{t("profile.currentPassword")}</Text>
                        <InputGroup size="md">
                            <Input
                                pr="4.5rem"
                                type={showCurrentPassword ? 'text' : 'password'}
                                size='sm'
                                value={currentPassword}
                                placeholder={t("profile.currentPassword")}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                            {currentPasswordError && (
                                <Alert status="error" mt={1}>
                                    <AlertIcon />
                                    {currentPasswordError}
                                </Alert>
                            )}
                            <InputRightElement width="4.5rem">
                                <Button mb='2' h="1.75rem" size="sm" onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                                    {showCurrentPassword ? 'Hide' : 'Show'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </Stack>
                </Box>
                <Box p={2}>
                    <Stack spacing={0} align={'left'} mb={1}>
                        <Text mb='8px'>{t("profile.newPassword")}</Text>
                        <InputGroup size="md">
                            <Input
                                type={showNewPassword ? 'text' : 'password'}
                                size='sm'
                                value={newPassword}
                                placeholder={t("profile.newPassword")}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <InputRightElement width="4.5rem">
                                <Button mb='2' h="1.75rem" size="sm" onClick={() => setShowNewPassword(!showNewPassword)}>
                                    {showNewPassword ? 'Hide' : 'Show'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </Stack>
                </Box>
                <Box p={2}>
                    <Stack spacing={0} align={'left'} mb={1}>
                        <Text mb='8px'>{t("profile.confirmPassword")}</Text>
                        <InputGroup size="md">
                            <Input
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                size={'sm'}
                                placeholder={t("profile.confirmPassword")}
                            />
                            <InputRightElement width="4.5rem">
                                <Button mb='2' h="1.75rem" size="sm" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                    {showConfirmPassword ? 'Hide' : 'Show'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </Stack>
                    {error && (
                        <Alert status="error" mt={2}>
                            <AlertIcon />
                            {error}
                        </Alert>
                    )}
                </Box>
                <Button
                    w={'full'}
                    mt={6}
                    bg={useColorModeValue('#00aad0', 'gray.900')}
                    color={'white'}
                    rounded={'md'}
                    onClick={handleChangePassword}
                    _hover={{
                        transform: 'translateY(-2px)',
                        boxShadow: 'lg',
                    }}
                >
                    {t("profile.save_changes")}
                </Button>
            </Box>
        </Stack>
    );
}
