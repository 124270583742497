import React from 'react'
import {
  ChakraProvider,
  Flex,
  Stack,
  Heading,
  Text,
  Avatar,
  Center
} from '@chakra-ui/react'

function ShowQuestion({ question })  {
  console.log(question);
  return (
  <Stack
    shadow={2}
    spacing={2}
    alignItems="flex-start"
    flexDirection="column"
    justifyContent="center"
    p={6}
  >
    <Center display="flex">
      <Stack spacing={2}>
        <Center>
          <Avatar src={question.author.image} />
        </Center>
        <Center m="0px">
          <Text>{question.author.name}</Text>
        </Center>
      </Stack>
    </Center>
    <Stack spacing={2}>
      <Center>
        <Heading color="blue.500">{question.title}</Heading>
      </Center>
      <div dangerouslySetInnerHTML={{ __html: question.content }} />
       
    
    </Stack>
  </Stack>
  )
}

ShowQuestion.defaultProps = { question: {title: "Titulo default", content: "Contenido",
author: {name: "nombre autor"}} };

export default ShowQuestion;