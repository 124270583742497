import { React, useEffect, useState}from 'react'
import { Grid, Box, chakra, Heading } from "@chakra-ui/react";
import HorizontalCard from './HorizontalCard';
import { useTranslation } from 'react-i18next';


export default function RecentQuestions({ posts }) {
  const { t, i18n } = useTranslation();

    return (

    <Box mt={4} minH={450} >
      <chakra.h2 fontSize="2xl" fontWeight="700" color={'blue.500'}>
        {t("forumInstructions.recentQuestions")}
      </chakra.h2>
      <Grid mt={2} templateColumns={['1fr', null, null, 'repeat(3, 1fr)']} gap={4}>
        {posts.map((post, index) => (
          <Box key={index} w="100%">
            <HorizontalCard props={post}></HorizontalCard>
          </Box>
          ))}
      </Grid>
    </Box>
  )

}

RecentQuestions.defaultProps = {
  posts: []
};