import { extendTheme } from "@chakra-ui/react";
import {  MultiSelectTheme } from 'chakra-multiselect'


export const customTheme = extendTheme({
  components: {
    MultiSelect: MultiSelectTheme
  },
   fonts: {
    body: 'Montserrat, sans-serif',
    heading: 'Raleway, sans-serif',
    // Puedes definir más fuentes aquí para otros estilos
  },
  colors: {
        gray: {500: "#a7a7a9"},  
        blue:{500: "#07C4D9"},
        primary: "#07C4D9",
        secondary: "#07C4D9",
    }
});


