import { useState } from 'react'
import { useAuthContext } from "../../AuthContext";
import { useFirestoreContext } from "../../FirestoreContext";
import TermConditionsModalPop from '../TermConditionsModal';
import {
  Icon,
  Progress,
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  Select,
  Text,
  InputLeftAddon,
  InputGroup,
  Textarea,
  FormHelperText,
  Link,
  Checkbox,
  Center,
  InputRightElement,
  useColorModeValue
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'chakra-multiselect';


const Form1 = ({ setName, setEmail, setPassword, setRethus, setSpeciality, setURL }) => {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [loaded, setLoaded] = useState(false);
  const { getSpecialities } = useFirestoreContext();
  const [cats, setCats] = useState([]); 
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);

  const handleProfileURLChange = (e) => {
    const inputValue = e.target.value;
  
    // Regular expression to check if the input contains only alphanumeric characters without spaces
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
  
    // Check if the input value is valid
    if (!alphanumericRegex.test(inputValue)) {
      // Display an alert for an incorrect profileURL
      alert(t('registerDr.profileUrlAlert'));
    } else {
      // Set the valid URL to state
      setURL(inputValue);
    }
  };
  

  if (!loaded){
    getSpecialities().then((res)=>setCats(res.data()));
    setLoaded(true);
  }

  return (
    <>
      <Heading w="100%" textAlign={'center'} fontWeight="normal" mb="2%">
      {t("registerDr.titleForm1")}
      </Heading>

      <FormControl mr="5%">
        <FormLabel htmlFor="name" fontWeight={'normal'}>
        {t("registerDr.displayName")}
        </FormLabel>
        <Input id="name"
          placeholder="name"
          onChange={(e) => setName(e.target.value)} />
          <FormHelperText mb={1}>{t("registerDr.displayNameInfo")}</FormHelperText>
      </FormControl>


      <FormControl mr="5%">
        <FormLabel htmlFor="profileurl" fontWeight={'normal'}>
          {t("registerDr.profileUrl")}
        </FormLabel>
        <Input id="profileurl"
          placeholder={t("registerDr.profileUrl")}
          onBlur={handleProfileURLChange} />
          <FormHelperText>{t("registerDr.profileUrlInfo")}</FormHelperText>
      </FormControl>

      <FormControl mt="2%">
        <FormLabel htmlFor="email" fontWeight={'normal'}>
        {t("registerDr.emailAddress")}
        </FormLabel>
        <Input id="email" type="email" onChange={(e) => setEmail(e.target.value)} />
        <FormHelperText>{t("registerDr.emailAddressInfo")}</FormHelperText>
      </FormControl>

      <FormControl mt="2%">
        <FormLabel htmlFor="rethus" fontWeight={'normal'}>
          ReTHUS
        </FormLabel>
        <Input id="rethus" type="text" onChange={(e) => setRethus(e.target.value)} />
        <FormHelperText>{t("registerDr.rethusInfo")}</FormHelperText>
      </FormControl>

      <FormControl id="categoria">
        <FormLabel mt={4}>{t("registerDr.speciality")}</FormLabel>
        <MultiSelect
          options={Object.keys(cats).map((item) => ({ value: item, label:cats[item] }))}
          value={selectedSpecialities}
          onChange={(values) => {
            console.log(values);
            setSelectedSpecialities(values)
            setSpeciality(values.map((value) => value.label).join(', ')); // Set the selected values as a comma-separated string
          }}
        />
      </FormControl>

      

      <FormControl>
        <FormLabel htmlFor="password" fontWeight={'normal'} mt="2%">
        {t("registerDr.password")}
        </FormLabel>
        <InputGroup size="md">
          <Input
            pr="4.5rem"
            type={show ? 'text' : 'password'}
            placeholder="Enter password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleClick}>
              {show ? 'Hide' : 'Show'}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </>
  )
}

const Form2 = ( { setCity, setStreetAddress, setWebsite, setAbout }) => {
  const { t, i18n } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const { getCities } = useFirestoreContext();
  const [cities, setCities] = useState([]); 
  const [isOpen, setIsOpen] = useState(false);
  const [isAcepted, setIsAcepted] = useState(false);
  const { isSignedUp } = false;
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);


  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  const toggleModalT = () => {
      setIsOpen(!isOpen);
     };

 const toggleAcepted = () => {
      console.log(isAcepted);
      setIsAcepted(!isAcepted);
     };

  if (!loaded){
    getCities().then((res)=>setCities(res.data()));
    setLoaded(true);
  }

  
  

  return (
    <>
      <Heading w="100%" textAlign={'center'} fontWeight="normal" mb="2%">
      {t("registerDr.titleForm2")}
      </Heading>   

      <FormControl id="city">
        <FormLabel mt={4}>{"Ciudad"}</FormLabel>
        <Select onChange={(e) => setCity(e.target.value)}>
          {Object.values(cities).map((item,key) => <option value={item}>{item}</option>)}
        </Select>
      </FormControl>

      <FormControl as={GridItem} colSpan={6}>
        <FormLabel
          htmlFor="street_address"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: 'gray.50',
          }}
          mt="2%">
          {t("registerDr.streetAddress")}
        </FormLabel>
        <Input
          type="text"
          name="street_address"
          id="street_address"
          autoComplete="street-address"
          focusBorderColor="brand.400"
          shadow="sm"
          size="sm"
          w="full"
          rounded="md"
          onChange={(e) => setStreetAddress(e.target.value)}
        />
      </FormControl>
      <FormControl as={GridItem} colSpan={[6, 3, null, 2]}>
        <FormControl as={GridItem} colSpan={[3, 2]}>
          <FormLabel
            fontSize="sm"
            mt={4}
            fontWeight="md"
            color="gray.700"
            _dark={{
              color: 'gray.50',
            }}>
            {t("registerDr.website")}
          </FormLabel>
          <InputGroup size="sm">
            <InputLeftAddon
              bg="gray.50"
              _dark={{
                bg: 'gray.800',
              }}
              color="gray.500"
              rounded="md">
              http://
            </InputLeftAddon>
            <Input
              type="tel"
              id="website"
              placeholder="www.example.com"
              focusBorderColor="brand.400"
              rounded="md"
              onChange={(e) => setWebsite(e.target.value)}
            />
          </InputGroup>
        </FormControl>

        <FormControl id="email" mt={4}>
          <FormLabel
            fontSize="sm"
            fontWeight="md"
            color="gray.700"
            _dark={{
              color: 'gray.50',
            }}>
            {t("registerDr.about")}
          </FormLabel>
          <Textarea
            placeholder="Resumen Biografia"
            rows={3}
            id="about"
            shadow="sm"
            focusBorderColor="brand.400"
            onChange={(e) => setAbout(e.target.value)}
            fontSize={{
              sm: 'sm',
            }}
          />
          <FormHelperText>
          {t("registerDr.aboutInfo")}
          </FormHelperText>
          <Flex p={5}  direction="column" >
            <Center>
            <Checkbox isChekced={'false'}>
            <Link mt={5} href={'/PrivacyPolicy'} target="_blank">{t("privacyPolicy.title")}</Link>
            </Checkbox>
            </Center>
            <Button mt={3} variant='ghost' onClick={toggleModalT}> 
                    {isAcepted ? <Icon m={5} as={CheckIcon}></Icon> : <></>}
                    <Text>Ver términos y condiciones  </Text>                   
            </Button>

                  { isSignedUp ? toggleModalT() :
                    <TermConditionsModalPop isOpen={isOpen} onClose={toggleModalT} acepted={toggleAcepted}/>
                  }
          </Flex>
        </FormControl>
      </FormControl>
    </>
  )
}

export function RegisterDoctor() {
  const [step, setStep] = useState(1)
  const [progress, setProgress] = useState(50)
  const [speciality, setSpeciality] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [rethus, setRethus] = useState('');
  const [url, setURL] = useState('');
  const [password, setPassword] = useState('');
  const [city, setCity] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [website, setWebsite] = useState('');
  const [about, setAbout] = useState('');
  const { createAccount } = useAuthContext();
  const { t, i18n } = useTranslation();
  const textColor = useColorModeValue('blue.500', 'blue.500');
  const bgColor = useColorModeValue('gray.200', 'gray.700');

  const handleCreateAccount = (event) => {
    // Check if profileURL is empty
  if (url.trim() === '') {
    alert(t('registerDr.profileUrlAlert'));
    return; // Stop execution if profileURL is empty
  }
    let userData = {
      displayName: name,
      profileURL: url,
      rethus: rethus,
      speciality: speciality,
      city: city,
      streetAddress: streetAddress,
      website: website,
      bio: about

    }
    console.log('Speciality:', speciality);
    event.preventDefault();
    createAccount(email, password, "doctor", userData);
  }

  return (
    <>
      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        p={6}
        my={4}
        width={"100%"}
        as="form">
        <Progress hasStripe value={progress} mb="5%" mx="5%" isAnimated></Progress>
        {step === 1 ? <Form1 setEmail={setEmail} setPassword={setPassword} setName={setName} setRethus={setRethus} setSpeciality={setSpeciality} setURL={setURL} /> : step === 2 ? <Form2 
            setCity={setCity}
            setStreetAddress={setStreetAddress}
            setWebsite={setWebsite}
            setAbout={setAbout} /> : null}
        <ButtonGroup mt="5%" w="100%">
          <Flex w="100%" justifyContent="space-between">
            <Flex>
              <Button
                onClick={() => {
                  setStep(step - 1)
                  setProgress(progress - 50)
                }}
                isDisabled={step === 1}
                bg={useColorModeValue('#00aad0', 'gray.900')}
                color={'white'}
                rounded={'md'}
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                  color: textColor,
                  bg: bgColor
                }}
                w="7rem"
                mr="5%">
                {t("registerDr.backButton")}
              </Button>
              <Button
                w="7rem"
                isDisabled={step === 2}
                onClick={() => {
                  setStep(step + 1)
                  if (step === 2) {
                    setProgress(100)
                  } else {
                    setProgress(progress + 50)
                  }
                }}
                bg={useColorModeValue('#00aad0', 'gray.900')}
                color={'white'}
                rounded={'md'}
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                  color: textColor,
                  bg: bgColor
                }}>
                {t("registerDr.nextButton")}
              </Button>
            </Flex>
            {step === 2 ? (
              <Button
                w="7rem"
                color={'white'}
                bg={'blue.500'}
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                  color: textColor,
                  bg: bgColor
                }}
                onClick={handleCreateAccount}
              >
                {t("registerDr.submitButton")}
              </Button>
            ) : null}
          </Flex>
          
        </ButtonGroup>
      </Box>
    </>
  )
}