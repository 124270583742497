import {
    Box,
    Flex,
    Heading,
    Text,
    Stack,
    Grid,
    Avatar,
    useColorModeValue,
    Accordion,
    SimpleGrid,
    AccordionItem, 
    AccordionButton, 
    AccordionPanel,
    AccordionIcon,
    
  } from '@chakra-ui/react'
import {Stars} from "./Stars"


const TestimonialContent = (props) => {
const { children } = props;


return (
  <Flex
  bg={useColorModeValue('white', 'gray.800')}
  boxShadow={'lg'}
  p={8}
  align={'center'}
  pos={'relative'}
  maxH={400}
  minH={400}
  minW={200}

  direction="column"
  justify="space-between"
>
  {children}
</Flex>
)
}

const TestimonialHeading = (props) => {
const { children } = props

return (
    <>
    <Flex flexDirection="column" alignItems="center"  justifyContent="center">
        <Stars Rating={props.Rating}></Stars>
        <Heading color={"blue.500"} as={'h3'} fontSize={'xl'} alignSelf="center">
          {children}
        </Heading>  
      </Flex>
    </>
)
}

const TestimonialText = (props) => {
const { children } = props

return (
    <Text
    textAlign={'center'}
    color={useColorModeValue('gray.600', 'gray.400')}
    fontSize={'sm'}>
    {children}
    </Text>
)
}

const TestimonialAvatar = ({
src,
name,
title,
}) => {
return (
    <Flex align={'center'} mt={8} direction={'column'}>
    <Avatar src={src} mb={2} />
    <Stack spacing={-1} align={'center'}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
        {title}
        </Text>
    </Stack>
    </Flex>
)
}

export function Testimonial({ Opinion }) {

    return (
      <Box width={"100%"}>
        <TestimonialContent>
          <TestimonialHeading as={Heading} Rating={Opinion.rate} color={"blue"}>{Opinion.title}</TestimonialHeading>
          <TestimonialText>
            {Opinion.content}
          </TestimonialText>
          <TestimonialAvatar
          src={Opinion.image}
          name={Opinion.author}
        />
        </TestimonialContent>
      </Box> // Debes cerrar el componente <Box> correctamente con </Box>
    );
  }


export function OpinionsOnly({opinions}){
    return (
        <SimpleGrid columns={[1, 2, 2, 3]} spacingY={"1"} spacingX={"1"}>


                        {Object.keys(opinions).map((item, key) => {return (<Testimonial Opinion={opinions[item]} key={key}></Testimonial>)})}

                    </SimpleGrid>
    )
}