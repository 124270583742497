import { Modal,
    Button,
    Box,
    Checkbox,
    ModalBody,
    ModalFooter,
    ModalContent,
    ModalHeader,
    ModalCloseButton, useDisclosure, ModalOverlay, Center, Text } from "@chakra-ui/react";

export default function TermConditionsModalPop({isOpen, onClose, acepted}) {

  return (
    <Box>
        <Modal 
              size={"2xl"}
              isOpen={isOpen}
              onClose={onClose}
              
              motionPreset='flip'>
                              <ModalOverlay
                              backdropFilter='blur(10px) hue-rotate(90deg)'/>
                              <ModalContent>
                                <ModalHeader>Términos y condiciones</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                  <Text fontSize={14}>
                                Políticas de Privacidad
                                  De acuerdo con La Ley 1581 de 2012, que garantiza la protección, almacenamiento y buen uso de datos personales, la presente información describe el uso de los datos personales de los clientes quienes ejercen su libre derecho para acceder a los servicios de BROCA GROUP S.A.S.

                                  Principios


                                  Principio de Legalidad: Cumplir con todos los requerimientos legales locales e internacionales respecto a la recolección, procesamiento y disposición final de la información personal, así como las políticas internas de la compañía.
                                  Principio de finalidad: El Tratamiento de la información en BROCA GROUP S.A.S, obedece a una finalidad legítima de acuerdo con la Constitución y la Ley, el cual se enmarcará en el manejo de la información para actividades relacionadas con su objeto social.
                                  Principio de libertad: El tratamiento sólo puede ejercerse con el consentimiento previo, expreso e informado del Titular. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que revele el consentimiento.
                                  Principio de veracidad o calidad: La información sujeta a Tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.
                                  Principio de Transparencia: En el tratamiento debe garantizase el derecho del Titular a obtener del Responsable del Tratamiento o del Encargado del Tratamiento, en cualquier momento y si restricciones, información acerca de la existencia de datos que le conciernen.
                                  Principio de acceso y circulación restringida: El Tratamiento se sujeta a los límites que se derivan de la naturaleza de los datos personales, de las disposiciones de la Ley 1581 de 2012 y la Constitución. En este sentido, el Tratamiento sólo podrá hacerse por personas autorizadas por el Titular y/o por las personas previstas en la presente ley.
                                  Principio de Seguridad: La información sujeta a Tratamiento por el Responsable del Tratamiento o Encargado del Tratamiento a que se refiere la ley 1581 de 2012, se deberá manejar con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.
                                  Principio de confidencialidad: Todas las personas que intervengan en el Tratamiento de datos personales que no tengan la naturaleza de públicos están obligados a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el Tratamiento, pudiendo sólo realizar suministro o comunicación de datos personales cuando ellos corresponda al desarrollo de las actividades autorizadas en la presente ley y en los términos de esta.
                                  Principio de Consentimiento: El Tratamiento sólo puede ejercerse con el consentimiento, previo, expreso e informado del Titular. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento.

                                  Los datos personales, salvo la información pública, no podrán estar disponibles en Internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los Titulares o terceros autorizados conforme a la Ley 1581 de 2012.

                                  Titular del uso
                                  El titular del uso de los datos personales es BROCA GROUP S.A.S ubicado en MEDELLÍN, Colombia.

                                  BROCA GROUP S.A.S tratará los datos personales del número de clientes recopilados durante la navegación y el uso de la Web, así como durante el registro y el envío de los pedidos de compra, a través de medios electrónicos y virtuales.

                                  Finalidad
                                  Los datos personales podrán ser utilizados para los siguientes fines:

                                  a) Ejecución de las obligaciones básicas y estrechamente relacionadas con el uso de la red, con el registro y el acceso a las áreas privadas del mismo, el envío de los pedidos y la gestión de la relación contractual y de post compra; b) Responder a solicitudes específicas e inquietudes demandadas; c) Cumplir con las obligaciones legales; d) Realización de estadísticas y evaluación del nivel de satisfacción del cliente, así como envío de promociones y de newsletters (mediante teléfono, correo electrónico, y también mediante comunicación virtual) a los contactos obtenidos en el formulario de registro en línea ubicado en la página web de la empresa; e) Uso de la información relacionada con el uso de la página web de la empresa (como la frecuencia de uso, modalidad, costumbres, intereses, estadísticas, pedidos, etc.)
                                  Carácter obligatorio o estrictamente necesario de comunicar los datos y consecuencias de un posible rechazo Por lo que se refiere a los fines mencionados en los apartados a), b) y c), la comunicación de los datos personales a imprenta contactada por medios virtuales a es BROCA GROUP S.A.S es obligatoria, y su correspondiente uso no requiere su autorización. En caso de negarse a facilitar dichos datos es BROCA GROUP S.A.S podrá verse imposibilitada a ejecutar la prestación de servicios y a realizar los pedidos. No es obligatorio facilitar los datos de los campos indicados como opcionales en el formulario de registro.

                                  La comunicación de los datos para los fines indicados en los apartados d) y e) es opcional, por lo que su omisión no tendrá ninguna repercusión. es BROCA GROUP S.A.S archiva el contenido del pedido de compra y puede ser enviado por correo electrónico junto con los términos y las condiciones de venta. Los detalles sobre los pedidos más recientes están disponibles en el área personal de cada cliente.

                                  Modalidad del uso
                                  Los datos personales son tratados exclusivamente con medios virtuales, con una lógica estrechamente relacionada con los fines mismos, y durante el tiempo estrictamente necesario para llevar a cabo los objetivos para los cuales se han recogido o para cumplir con los requisitos legales necesarios. Se adoptan medidas de seguridad especiales para impedir la pérdida de datos, usos ilícitos o incorrectos y accesos no autorizados. La información recopilada se registra de forma segura. Ámbito de comunicación de los datos Los datos personales no serán difundidos y podrán ser comunicados únicamente para los fines anteriormente mencionados, a terceros que actúen por cuenta o en nombre de es BROCA GROUP S.A.S en calidad de sujetos encargados de ocuparse de la entrega de los productos, entidades de crédito, asesores legales y fiscales, outsourcers, etc.

                                  Para los fines anteriormente citados, el personal encargado de es BROCA GROUP S.A.S o los responsables de su uso podrán acceder a los datos. El responsable del uso de dichos datos recae en la figura del Director General/ Comercial. En la sede de la sociedad se encuentra disponible una lista actualizada de los responsables del uso de los datos personales.

                                  Contacto
                                  Nuestra Política de Protección de Datos reposa en un archivo automatizado ubicado en el equipo de administración o si quiere conocer algo relacionado con el tema favor comunicarse al 3002031988 o al correo rincon.ana@brocagroup.com de la tienda.
                                  </Text>
                                </ModalBody>

                                <ModalFooter>
                                  <Center w={"100%"}>
                                  <Button m={6} onClick={onClose} colorScheme='blue'>
                
                                    <Checkbox marginLeft={5} onChange={(e) => acepted("sisas")}
                                      fontSize={'2xl'} fontWeight={300} fontFamily={'body'} >
                                        Aceptar términos y condiciones
                                      
                                    </Checkbox>
                                  </Button>
                                  </Center>
                                </ModalFooter>
                              </ModalContent>
            </Modal>
    </Box>
  )  
}
                    
